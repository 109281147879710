const _temp0 = require("./images/gallery/art_1.jpg");
const _temp1 = require("./images/gallery/art_10.jpg");
const _temp2 = require("./images/gallery/art_11.jpg");
const _temp3 = require("./images/gallery/art_12.jpg");
const _temp4 = require("./images/gallery/art_13.jpg");
const _temp5 = require("./images/gallery/art_14.jpg");
const _temp6 = require("./images/gallery/art_15.jpg");
const _temp7 = require("./images/gallery/art_16.jpg");
const _temp8 = require("./images/gallery/art_17.jpg");
const _temp9 = require("./images/gallery/art_18.jpg");
const _temp10 = require("./images/gallery/art_19.jpg");
const _temp11 = require("./images/gallery/art_2.jpg");
const _temp12 = require("./images/gallery/art_20.jpg");
const _temp13 = require("./images/gallery/art_21.jpg");
const _temp14 = require("./images/gallery/art_22.jpg");
const _temp15 = require("./images/gallery/art_23.jpg");
const _temp16 = require("./images/gallery/art_24.jpg");
const _temp17 = require("./images/gallery/art_25.jpg");
const _temp18 = require("./images/gallery/art_26.jpg");
const _temp19 = require("./images/gallery/art_27.jpg");
const _temp20 = require("./images/gallery/art_28.jpg");
const _temp21 = require("./images/gallery/art_29.jpg");
const _temp22 = require("./images/gallery/art_3.jpg");
const _temp23 = require("./images/gallery/art_30.jpg");
const _temp24 = require("./images/gallery/art_31.jpg");
const _temp25 = require("./images/gallery/art_32.jpg");
const _temp26 = require("./images/gallery/art_33.jpg");
const _temp27 = require("./images/gallery/art_34.jpg");
const _temp28 = require("./images/gallery/art_35.jpg");
const _temp29 = require("./images/gallery/art_36.jpg");
const _temp30 = require("./images/gallery/art_37.jpg");
const _temp31 = require("./images/gallery/art_38.jpg");
const _temp32 = require("./images/gallery/art_39.jpg");
const _temp33 = require("./images/gallery/art_4.jpg");
const _temp34 = require("./images/gallery/art_40.jpg");
const _temp35 = require("./images/gallery/art_41.jpg");
const _temp36 = require("./images/gallery/art_42.jpg");
const _temp37 = require("./images/gallery/art_43.jpg");
const _temp38 = require("./images/gallery/art_44.jpg");
const _temp39 = require("./images/gallery/art_45.jpg");
const _temp40 = require("./images/gallery/art_46.jpg");
const _temp41 = require("./images/gallery/art_47.jpg");
const _temp42 = require("./images/gallery/art_48.jpg");
const _temp43 = require("./images/gallery/art_49.jpg");
const _temp44 = require("./images/gallery/art_5.jpg");
const _temp45 = require("./images/gallery/art_50.jpg");
const _temp46 = require("./images/gallery/art_51.jpg");
const _temp47 = require("./images/gallery/art_52.jpg");
const _temp48 = require("./images/gallery/art_53.jpg");
const _temp49 = require("./images/gallery/art_54.jpg");
const _temp50 = require("./images/gallery/art_55.jpg");
const _temp51 = require("./images/gallery/art_56.jpg");
const _temp52 = require("./images/gallery/art_57.jpg");
const _temp53 = require("./images/gallery/art_58.jpg");
const _temp54 = require("./images/gallery/art_6.jpg");
const _temp55 = require("./images/gallery/art_7.jpg");
const _temp56 = require("./images/gallery/art_8.jpg");
const _temp57 = require("./images/gallery/art_9.jpg");
module.exports = {
  "art_1": _temp0,
  "art_10": _temp1,
  "art_11": _temp2,
  "art_12": _temp3,
  "art_13": _temp4,
  "art_14": _temp5,
  "art_15": _temp6,
  "art_16": _temp7,
  "art_17": _temp8,
  "art_18": _temp9,
  "art_19": _temp10,
  "art_2": _temp11,
  "art_20": _temp12,
  "art_21": _temp13,
  "art_22": _temp14,
  "art_23": _temp15,
  "art_24": _temp16,
  "art_25": _temp17,
  "art_26": _temp18,
  "art_27": _temp19,
  "art_28": _temp20,
  "art_29": _temp21,
  "art_3": _temp22,
  "art_30": _temp23,
  "art_31": _temp24,
  "art_32": _temp25,
  "art_33": _temp26,
  "art_34": _temp27,
  "art_35": _temp28,
  "art_36": _temp29,
  "art_37": _temp30,
  "art_38": _temp31,
  "art_39": _temp32,
  "art_4": _temp33,
  "art_40": _temp34,
  "art_41": _temp35,
  "art_42": _temp36,
  "art_43": _temp37,
  "art_44": _temp38,
  "art_45": _temp39,
  "art_46": _temp40,
  "art_47": _temp41,
  "art_48": _temp42,
  "art_49": _temp43,
  "art_5": _temp44,
  "art_50": _temp45,
  "art_51": _temp46,
  "art_52": _temp47,
  "art_53": _temp48,
  "art_54": _temp49,
  "art_55": _temp50,
  "art_56": _temp51,
  "art_57": _temp52,
  "art_58": _temp53,
  "art_6": _temp54,
  "art_7": _temp55,
  "art_8": _temp56,
  "art_9": _temp57
}