import { gsap } from "gsap";
import barba from "@barba/core";
import LocomotiveScroll from "locomotive-scroll";
// Vendors
import "locomotive-scroll/dist/locomotive-scroll.css";
// All images from vizualizer folder...
import * as visualizers from "./images/vizualizers/*.webp";
import * as music_videos from "./images/music_vids/*.webp";
import * as shorts from "./images/animated_shorts/*.webp";
import * as gallerys from "./images/thumbnails/*.webp";
import * as gallery_images from "./images/gallery/*.jpg";
import play_button from "./images/play-button.png";
//Media
import sound_on from "./images/sound_on.png";
import sound_off from "./images/sound_toggle.png";
import play_button from "./images/play.png";
import pause from "./images/pause.png";

const nav = document.querySelector("nav");
const menu_container = document.querySelector(".menu_container");

const menu_items = document.querySelector('.menu_items')
const menu_items_array = [...menu_items.children]
const menu_buttons = menu_items.querySelectorAll('.main_menu')
const works_menu = menu_items.querySelectorAll('.works_menu')
const mobile_screen = window.matchMedia("(max-width:500px)");
const nav_footer = document.querySelector('.nav_footer')

var scroll;

const initScroll = () => {
    scroll = new LocomotiveScroll({
        lenisOptions: {
            wrapper: window,
            content: document.querySelector("[data-scroll-container]"),
        },
        smooth: true,
        mobile: {
            smooth: true
        }
    });

    // Update the scroll after initialization
    // scroll.update();
};

const destroyScroll = () => {
    if (scroll) {
        scroll.destroy();
        scroll = null;
    }
};

const page_transition = document.querySelectorAll(".page_transition div");
const tl = gsap.timeline();
barba.init({
    sync: true,

    transitions: [
        {
            name: "page-transition",

            leave(data) {
                const done = this.async();

                // Page content fade out and move up
                gsap.to(data.current.container, {
                    y: -200,
                    opacity: 0,
                    duration: 1,
                    onComplete: function () {
                        done(); // Notify Barba that the transition is done
                    },
                });

                // Transition overlay animation
                tl.to(page_transition, {
                    duration: 0.5,
                    display: "flex",
                    y: 0,
                    stagger: 0.1,
                });

                tl.to(page_transition, {
                    duration: 0.5,
                    delay: 0.3,
                    y: "-100%",
                    stagger: 0.15,
                });



            },

            beforeEnter() {

                destroyScroll(); // Destroy scroll before entering the new page


            },

            async enter(data) {
                // Page content animation
                gsap.from(data.next.container, {
                    y: -100,
                    opacity: 0,
                    delay: 0.3,
                    duration: 1,
                    ease: "power2.out",
                });

                // Initialize Locomotive Scroll after the animations are complete
                // await new Promise(resolve => {
                setTimeout(() => {
                    initScroll();

                    menu_items_array.forEach(items => {
                        items.style.width = '0'
                    })

                    menu_buttons.forEach(items => {
                        items.style.cssText = `
                            top:200px
                        `
                    })

                    gsap.to(menu_container, {
                        opacity: 0,
                        onComplete: () => {
                            menu_container.classList.remove("active");
                        },
                    });

                    gsap.fromTo(
                        nav,
                        {
                            height: "100svh",
                        },
                        {
                            height: "83px",
                            duration: 1,
                            ease: "power4.out",
                        }
                    );

                    gsap.to(works_menu, {
                        opacity: 0,
                        top: '200px',
                        duration: 1,
                        stagger: .2,
                        ease: 'power4.in',
                    })

                    if (mobile_screen.matches) {
                        gsap.fromTo(
                            nav,
                            {
                                height: "100dvh",
                            },
                            {
                                height: "72px",
                                duration: 1,
                                ease: "power4.out",
                                onComplete: () => {
                                    if (mobile_screen.matches) {
                                        gsap.to(nav_footer, {
                                            opacity: 0
                                        })
                                    }
                                }
                            }
                        );


                        //Gradient backgroudn height adaptation functions begins here...
                        const gradient_background = document.querySelector(
                            ".gradient_background"
                        );
                        const works_container = document.querySelector(".works_container");

                        const works_height = works_container.getBoundingClientRect().height;
                        gradient_background.style.height = works_height + "px";

                        const layout = document.querySelector('.layout')
                        layout.style.height = works_height + 100 + 'px'
                        console.log(layout)

                        works_container.style.height = works_height + 100 + 'px'
                    }

                    const menu_arrow = document.querySelector('.menu_arrow')
                    const arrow_class = menu_arrow.getAttribute('class')


                    if (!arrow_class.includes('toggle_arrow')) {
                        console.log(arrow_class)
                        return
                    } else {
                        menu_arrow.classList.toggle('toggle_arrow')
                    }



                }, 100);

                //Reesponive Height for mobile screens




            },

            once(data) {
                initScroll(); // Initialize scroll on first load

                // Animate in the page content
                gsap.from(data.next.container, {
                    y: -100,
                    opacity: 1,
                    duration: 1,
                    delay: 0.3,
                    ease: "power2.out",
                });
            },
        },
    ],
    views: [
        {
            namespace: "home",
            afterEnter() {
                const parentFunction = () => {
                    //Mobile View
                    const mobile_screen = window.matchMedia("(max-width:500px)");
                    const nav_footer = document.querySelector('.nav_footer')
                    const layout = document.querySelector('.layout')
                    layout.style.display = 'none'

                    //Responsive Navbar
                    const nav = document.querySelector("nav");
                    const menu_container = document.querySelector(".menu_container");
                    let lastScrollPosition = 0;

                    var cursorBool = false;

                    menu_container.addEventListener("mouseenter", () => {
                        cursorBool = true;
                    });

                    nav.addEventListener("mouseleave", () => {
                        cursorBool = false;
                    });

                    menu_container.addEventListener("touchmove", () => {
                        cursorBool = true;
                    });

                    // nav.addEventListener("touchend", () => {
                    //     cursorBool = false;
                    // });

                    window.addEventListener("scroll", () => {
                        if (mobile_screen.matches) {
                            if (cursorBool == true) {
                                return;

                            }

                            nav.classList.remove("add_nav");

                        } else {
                            if (cursorBool == true) {
                                return;

                            }

                            const currentScrollPosition = window.scrollY;

                            if (currentScrollPosition > lastScrollPosition) {
                                // Scrolling down

                                nav.classList.add("add_nav");
                            } else if (currentScrollPosition < lastScrollPosition) {
                                // Scrolling up

                                nav.classList.remove("add_nav");
                            }

                            // Update the last scroll position
                            lastScrollPosition = currentScrollPosition;
                        }

                    });


                    //Menu pane codes here...
                    const menu = document.querySelector(".menu_trigger");

                    const menu_items = document.querySelector(".menu_items");
                    const menu_items_array = [...menu_items.children];
                    const menu_buttons = menu_items.querySelectorAll(".main_menu");
                    const works_menu = menu_items.querySelectorAll(".works_menu");
                    const menu_arrow = document.querySelector(".menu_arrow");
                    const arrow_menu_array = document.querySelectorAll('.menu_arrow, .arrow_menu')
                    const timeout = 500;
                    var toggle_bool = true;
                    const timeline = gsap.timeline();

                    toggle_bool = !toggle_bool;

                    menu.onclick = () => {
                        //nav.classList.toggle('toggle_nav')

                        if ((toggle_bool = !toggle_bool)) {
                            gsap.fromTo(
                                nav,
                                {
                                    height: "auto",
                                },
                                {
                                    height: "100dvh",
                                    duration: 1,
                                    ease: "power4.out",
                                    //  onComplete:()=>{
                                    //     menu_container.classList.add('active')
                                    //  }
                                }
                            );

                            menu_container.classList.add("active");
                            gsap.to(menu_container, {
                                opacity: 1,
                            });

                            setTimeout(() => {
                                timeline.to(menu_items_array, {
                                    width: "100%",
                                    duration: .8,
                                    stagger: 0.2,
                                    ease: "power4.out",
                                });
                                timeline.to(menu_buttons, {
                                    top: "0",
                                    duration: .8,
                                    ease: "power4.out",
                                    delay: -1,
                                });
                            }, timeout);

                            gsap.to(nav_footer, {
                                opacity: 1,
                                delay: 1.3
                            })

                            // nav.style.backgroundColor = '#141321e3'
                        } else {
                            menu_container.classList.remove("active");
                            gsap.to(menu_container, {
                                opacity: 0,
                            });

                            menu_items_array.forEach((items) => {
                                items.style.width = "0";
                            });

                            menu_buttons.forEach((items) => {
                                items.style.cssText = `
                top:200px
            `;
                            });

                            gsap.fromTo(
                                nav,
                                {
                                    height: "100svh",
                                },
                                {
                                    height: "83px",
                                    duration: 1,
                                    ease: "power4.out",
                                }
                            );

                            if (mobile_screen.matches) {
                                gsap.fromTo(
                                    nav,
                                    {
                                        height: "100dvh",
                                    },
                                    {
                                        height: "72px",
                                        duration: 1,
                                        ease: "power4.out",
                                        onComplete: () => {
                                            if (mobile_screen.matches) {
                                                gsap.to(nav_footer, {
                                                    opacity: 0
                                                })
                                            }
                                        }
                                    }
                                );
                            }

                            // nav.style.backgroundColor = '#14132185'
                        }
                    };

                    var works_bool = true;
                    const menu_tl = gsap.timeline();
                    works_bool = !works_bool;

                    arrow_menu_array.forEach(items => {
                        items.onclick = () => {
                            menu_arrow.classList.toggle('toggle_arrow')


                            if (works_bool = !works_bool) {

                                menu_tl.to(menu_buttons, {
                                    top: '200px',
                                    duration: .8,
                                    ease: 'power4.in',
                                })

                                menu_tl.to(works_menu, {
                                    opacity: 1,
                                    top: '0',
                                    duration: .8,
                                    ease: 'power4.out',
                                })

                            } else {
                                menu_tl.to(works_menu, {
                                    opacity: 0,
                                    top: '200px',
                                    duration: .8,
                                    ease: 'power4.in',
                                })

                                menu_tl.to(menu_buttons, {
                                    top: '0',
                                    duration: .8,
                                    ease: 'power4.out',
                                })
                            }
                        }
                    })


                    /* Custom event */
                    window.addEventListener("scrollEvent", (e) => {
                        const { target, way } = e.detail;

                        if (way === "enter") {
                            target.style.opacity = 1;
                            target.style.paddingTop = 0;
                        } else {
                            target.style.opacity = 0;
                        }
                    });

                    const works_heading = document.querySelector("#works_heading");
                    const challenge_img = document.querySelector(".challenge_img");
                    const challenge_content = document.querySelector("#challenge_content");
                    const contact_img = document.querySelector(".contact_img");
                    const contact_content = document.querySelector("#contact_content");
                    const footer_arr = [
                        challenge_img,
                        challenge_content,
                        contact_img,
                        contact_content,
                    ];
                    const arrow_up = document.querySelector("#arrow_up");
                    const arrow_down = document.querySelector("#arrow_down");
                    const video_trey = document.querySelector(".video_trey");

                    const works_array = "OUR WORKS".split("");

                    works_array.forEach((items) => {
                        const span = document.createElement("span");
                        span.textContent = items;

                        works_heading.appendChild(span);
                    });

                    const spans = works_heading.querySelectorAll("span");

                    window.addEventListener("animTrigger", () => {
                        gsap.to(spans, {
                            alpha: 1,
                            top: 0,
                            stagger: 0.1,
                            duration: 1.5,
                            ease: "power4.out",
                        });
                    });

                    window.addEventListener("contactTrigger", () => {
                        gsap.to(footer_arr, {
                            alpha: 1,
                            stagger: 0.2,
                            duration: 1.5,
                            ease: "power4.out",
                        });
                    });

                    window.addEventListener("latestTrigger", () => {
                        //latest Project Loader
                        const svg = document.querySelector("svg circle");
                        const content_trey = document.querySelector(".content_trey");
                        const flex2_trey = document.querySelector(".flex2_trey");
                        const last_night_caption_trey = document.querySelector(
                            ".last_night_caption_trey"
                        );
                        const poster2_trey = document.querySelector(".poster2_trey");
                        const latest_array = [
                            content_trey,
                            flex2_trey,
                            last_night_caption_trey,
                            poster2_trey,
                        ];

                        const timer = 12000;
                        var index = 1;
                        var total_index = 2;
                        const duration_time = 11;
                        let loadInterval;

                        const svg_tl = gsap.timeline({ paused: false });

                        svg_tl.to(svg, {
                            strokeDashoffset: 0,
                            duration: duration_time,
                        });

                        function timeInterval() {
                            loadInterval = setInterval(() => {
                                if (index < total_index) {
                                    svg_tl.to(svg, {
                                        strokeDashoffset: 251,
                                        duration: duration_time,
                                    });
                                    svg.style.stroke = "red";
                                    gsap.to(latest_array, {
                                        transform: "translate(-50%)",
                                        stagger: 0.2,
                                        ease: "power4.out",
                                        duration: 1,
                                    });
                                    index++;
                                } else {
                                    index = 1;
                                    svg_tl.to(svg, {
                                        strokeDashoffset: 0,
                                        duration: duration_time,
                                    });
                                    gsap.to(latest_array, {
                                        transform: "translate(0%)",
                                        stagger: 0.2,
                                        ease: "power4.out",
                                        duration: 1,
                                    });
                                    svg.style.stroke = "blue";
                                    // loading_bar.style.transition = 'none'
                                    // loading_bar.style.width = '0'
                                    //loading_bar.style.display='none'
                                }
                            }, timer);
                        }

                        timeInterval();

                        const pause_button = document.querySelector(".pause_button");
                        var pause_bool = true;

                        pause_bool = !pause_bool;
                        pause_button.onclick = (e) => {
                            svg_tl.paused(!svg_tl.paused());
                            if ((pause_bool = !pause_bool)) {
                                e.target.setAttribute("src", play_button);
                                clearInterval(loadInterval);
                            } else {
                                e.target.setAttribute("src", pause);
                                timeInterval();
                            }
                        };
                    });

                    var move_amount = 80;
                    var value = 0;
                    var index = 1;
                    var total_index = video_trey.children.length;

                    function reelDown() {
                        if (index < total_index) {
                            video_trey.style.transform = `translateY(${(value -=
                                move_amount)}vh)`;
                            index++;
                        }
                    }

                    arrow_down.onclick = () => {
                        reelDown();
                    };

                    function reelUp() {
                        if (index > 1) {
                            index--;
                            video_trey.style.transform = `translateY(${(value +=
                                move_amount)}vh)`;
                        }
                    }

                    arrow_up.onclick = () => {
                        reelUp();
                    };

                    //Scroll Trigger
                    window.addEventListener("reelTrigger", () => {
                        const reel_heading = document.querySelector(".reel_heading");
                        const reel_detail = document.querySelector("#details");
                        const reels = document.querySelector("#reels");
                        const reel_buttons = document.querySelector("#reel_buttons");
                        const reel_array = [reel_heading, reel_detail, reels, reel_buttons];

                        gsap.to(reel_array, {
                            alpha: 1,
                            stagger: 0.2,
                        });
                    });

                    // Toggle light or dark mode
                    const toggle_mode = document.querySelector(".toggle_mode");
                    var toggle = true;
                    var key = "toggle_key";
                    toggle = !toggle;
                    const local_toggle = localStorage.getItem(key);

                    toggle_mode.onclick = () => {
                        if ((toggle = !toggle)) {
                            localStorage.setItem(key, toggle);
                            document.body.classList.add("darkmode");
                        } else {
                            localStorage.setItem(key, toggle);
                            document.body.classList.remove("darkmode");
                        }
                    };

                    if (local_toggle === "true") {
                        document.body.classList.add("darkmode");
                    }

                    if (local_toggle === "false") {
                        document.body.classList.remove("darkmode");
                    }

                    const reel_button = document.querySelector("#reel_button");
                    const banner_trey = document.querySelector(".banner_trey");
                    const banner_mute = document.querySelector(".banner_mute");
                    const banner_caption = document.querySelector(".banner_caption");
                    const banner_description = document.querySelector(
                        ".banner_description"
                    );
                    var reel_bool = true;

                    reel_bool = !reel_bool;

                    //Loading Animation starts here..
                    const loading = document.querySelector(".loading");
                    const loading_screen = document.querySelector(".loading_screen");
                    const timelne = gsap.timeline();

                    if (loading) {
                        gsap.to(loading, {
                            delay: 1,
                            transform: "scale(1.2)",
                            duration: 3,

                            onComplete: () => {
                                timelne.to(loading, {
                                    transform: "scale(1.3)",
                                    duration: 0.5,
                                });

                                timelne.to(loading, {
                                    delay: 0.3,
                                    transform: "scale(1)",
                                    duration: 0.25,
                                    onComplete: () => {
                                        loading.style.maskImage = "none";
                                        loading_screen.remove();
                                    },
                                });
                            },
                        });
                    }

                    //Mobile view codes
                    const main = document.querySelector(".main");
                    const portfolio_container = document.querySelector(
                        ".portfolio_container"
                    );
                    const card1 = document.querySelector("#card1");
                    const card2 = document.querySelector("#card2");
                    const card3 = document.querySelector("#card3");
                    const card4 = document.querySelector("#card4");
                    const caption = document.querySelector("#caption");

                    if (mobile_screen.matches) {
                        document.addEventListener("scroll", (e) => {
                            var scrollTop = document.scrollingElement.scrollTop;
                            var main_position = main.offsetTop;
                            const portfolio_position = portfolio_container.offsetTop;

                            card1.setAttribute("data-scroll-speed", "0");
                            card2.setAttribute("data-scroll-speed", "0");
                            card4.setAttribute("data-scroll-speed", "0");
                            // if(scrollTop > main_position+300){
                            //     caption.style.transform = `translateY(-${(scrollTop-(main_position+600))/10}%)`
                            // }

                            // if (scrollTop > portfolio_position - 300) {
                            //     card1.style.transform = `translate(-3%,-${(scrollTop - (portfolio_position - 300)) / 13}%)`
                            // }

                            // if (scrollTop > portfolio_position - 300) {
                            //     card2.style.transform = `translate(0%,-${(scrollTop - (portfolio_position - 300)) / 6}%)`
                            // }
                            // if (scrollTop > portfolio_position - 300) {
                            //     card3.style.transform = `translate(0%,-${(scrollTop - (portfolio_position - 300)) / 9}%)`
                            // }

                            // if (scrollTop > portfolio_position + 200) {
                            //     card4.style.transform = `translate(0%,-${(scrollTop - (portfolio_position + 200)) / 13}%)`
                            // }
                        });
                    } else {
                        card1.setAttribute("data-scroll-speed", ".2");
                        card2.setAttribute("data-scroll-speed", ".2");
                        card4.setAttribute("data-scroll-speed", ".1");
                    }

                    //Reels Section js codes here...
                    const reel_descrip = document.querySelector(".reel_descrip");
                    const summary = String(reel_descrip.innerText).slice(0, 60);


                    reel_descrip.innerHTML = summary + "...";

                    //Hover for video preview
                    const trigger = document.querySelector(".viz_trigger");
                    const music_trigger = document.querySelector(".music_trigger");
                    const film_trigger = document.querySelector(".film_trigger");
                    const film_cover_vid = document.querySelector(".film_cover video");
                    const cover_vid = document.querySelector(".cover video");
                    const music_cover_vid = document.querySelector(".music_cover video");
                    const music_cover = document.querySelector(".music_cover");
                    const film_cover = document.querySelector(".film_cover");
                    const film_details = document.querySelector(".film_details");
                    const film_p = document.querySelector(".film_p");
                    const card_2 = document.querySelector(".card2");
                    const music_details = document.querySelector(".music_details");
                    const viz_cover = document.querySelector(".viz_cover");
                    const viz_details = document.querySelector(".viz_details");
                    const illustration_cover = document.querySelector(
                        ".illustration_cover"
                    );
                    const illustration_details = document.querySelector(
                        ".illustration_details"
                    );

                    trigger.onmouseover = (e) => {
                        (cover_vid.style.opacity = 1),
                            (cover_vid.style.visibility = "visible");
                        cover_vid.style.display = "block";
                        viz_cover.style.transform = "scale(1.2)";
                        viz_details.style.transform = "translateY(max(60px,5vh))";


                    };

                    trigger.onmouseleave = (e) => {
                        (cover_vid.style.opacity = 0),
                            (cover_vid.style.visibility = "hidden");
                        cover_vid.style.display = "none";
                        viz_cover.style.transform = "scale(1)";
                        viz_details.style.transform = "translateY(0px)";
                    };

                    music_trigger.onmouseover = (e) => {
                        music_cover_vid.style.opacity = 1;
                        music_cover_vid.style.visibility = "visible";
                        music_cover_vid.style.display = "block";
                        music_cover.style.transform = "scale(1.2)";


                        music_details.style.transform = "translateY(max(60px,5vh))";
                    };

                    music_trigger.onmouseleave = (e) => {
                        music_cover_vid.style.opacity = 0;
                        music_cover_vid.style.visibility = "hidden";
                        music_cover_vid.style.display = "none";
                        music_cover.style.transform = "scale(1)";
                        music_details.style.transform = "translateY(0px)";
                    };

                    film_trigger.onmouseover = (e) => {
                        film_cover_vid.style.opacity = 1;
                        film_cover_vid.style.visibility = "visible";
                        film_cover_vid.style.display = "block";
                        film_cover.style.transform = "scale(1.15)";
                        film_details.style.transform = "translateY(50px)";
                        film_p.style.transform = "translateY(50px)";
                    };

                    film_trigger.onmouseleave = () => {
                        film_cover_vid.style.opacity = 0;
                        film_cover_vid.style.visibility = "hidden";
                        film_cover_vid.style.display = "none";
                        film_cover.style.transform = "scale(1)";
                        film_details.style.transform = "translateY(0px)";
                        film_p.style.transform = "translateY(0px)";
                    };

                    card4.onmouseover = () => {
                        illustration_cover.style.transform = "scale(1.2)";
                        illustration_details.style.transform = "translateY(max(40px,5vh))";
                    };

                    card4.onmouseleave = () => {
                        illustration_cover.style.transform = "scale(1)";
                        illustration_details.style.transform = "translateY(0px)";
                    };

                    //Toggle Sound
                    const banner_mute_img = document.querySelector(".banner_mute img");
                    var sound_bool = true;
                    const last_night_trailer = document.querySelector(
                        "#last_night_trailer"
                    );
                    const reel_video = document.querySelector("#reel_video");

                    sound_bool = !sound_bool;

                    banner_mute.onclick = () => {
                        if ((sound_bool = !sound_bool)) {
                            banner_mute_img.setAttribute("src", sound_on);
                        } else {
                            banner_mute_img.setAttribute("src", sound_off);
                        }

                        last_night_trailer.muted = !last_night_trailer.muted;
                    };

                    reel_button.onclick = () => {
                        last_night_trailer.muted = true;
                        reel_video.muted = true;
                        if (sound_bool == true) {
                            banner_mute_img.setAttribute("src", sound_off);
                            sound_bool = false;
                        }

                        if ((reel_bool = !reel_bool)) {
                            banner_trey.style.left = "-100%";
                            reel_button.innerHTML = "&larr; Last Night";
                            banner_caption.textContent = "Our Showreel";
                            banner_description.textContent =
                                ' “A project that kept our creative adrenaline juices flowing, it could have been a cocktail recipe."';
                        } else {
                            banner_trey.style.left = "0%";
                            reel_button.innerHTML = "Showreel &rarr;";
                            banner_caption.textContent = "Last Night";
                            banner_description.textContent =
                                " “One. Night. Of. Pleasure? Started out that way The Nightmare was in the Shadows…Waiting”";
                        }

                        if (reel_bool == false) {
                            banner_mute.onclick = () => {
                                if ((sound_bool = !sound_bool)) {
                                    banner_mute_img.setAttribute("src", sound_on);
                                } else {
                                    banner_mute_img.setAttribute("src", sound_off);
                                }
                                last_night_trailer.muted = !last_night_trailer.muted;
                            };
                        } else {
                            banner_mute.onclick = () => {
                                if ((sound_bool = !sound_bool)) {
                                    banner_mute_img.setAttribute("src", sound_on);
                                } else {
                                    banner_mute_img.setAttribute("src", sound_off);
                                }

                                reel_video.muted = !reel_video.muted;
                            };
                        }
                    };
                };

                try {
                    parentFunction();
                    const all_video = document.querySelectorAll("video");

                    all_video.forEach((items) => {
                        items.play();
                    });
                } catch (error) { }
            },
        },
        {
            namespace: "vizualizers",
            afterEnter() {

                setTimeout(() => {
                    initScroll();
                    let scrollX = 0
                    let scrollY = 0

                    barba.hooks.leave(() => {
                        scrollX = barba.history.current.scroll.x;
                        scrollY = barba.history.current.scroll.y;
                    });

                    // then later in the code...
                    window.scrollTo(scrollX, scrollY);



                }, 500);

                //Responsive Navbar
                const nav = document.querySelector("nav");
                const menu_container = document.querySelector(".menu_container");
                let lastScrollPosition = 0;

                var cursorBool = false;

                menu_container.addEventListener("mouseenter", () => {
                    cursorBool = true;
                });

                nav.addEventListener("mouseleave", () => {
                    cursorBool = false;
                });

                menu_container.addEventListener("touchmove", () => {
                    cursorBool = true;
                });

                // nav.addEventListener("touchend", () => {
                //     cursorBool = false;
                // });

                
                    if (mobile_screen.matches) {
                        window.addEventListener("scroll", () => {
                            if (cursorBool == true) {
                                return;
    
                            }
    
                            nav.classList.remove("add_nav");
                        })
                       

                    } else {
                        window.addEventListener("scroll", () => {
                            if (cursorBool == true) {
                                return;
    
                            }
    
                            const currentScrollPosition = window.scrollY;
    
                            if (currentScrollPosition > lastScrollPosition) {
                                // Scrolling down
    
                                nav.classList.add("add_nav");
                            } else if (currentScrollPosition < lastScrollPosition) {
                                // Scrolling up
    
                                nav.classList.remove("add_nav");
                            }
    
                            // Update the last scroll position
                            lastScrollPosition = currentScrollPosition;
                        })
                     
                    }

               


                //Menu pane codes here...
                const menu = document.querySelector(".menu_trigger");

                const menu_items = document.querySelector(".menu_items");
                const menu_items_array = [...menu_items.children];
                const menu_buttons = menu_items.querySelectorAll(".main_menu");
                const works_menu = menu_items.querySelectorAll(".works_menu");
                const menu_arrow = document.querySelector(".menu_arrow");
                const arrow_menu_array = document.querySelectorAll('.menu_arrow, .arrow_menu')
                const timeout = 500;
                var toggle_bool = true;
                const timeline = gsap.timeline();

                toggle_bool = !toggle_bool;

                menu.onclick = () => {
                    //nav.classList.toggle('toggle_nav')

                    if ((toggle_bool = !toggle_bool)) {
                        gsap.fromTo(
                            nav,
                            {
                                height: "auto",
                            },
                            {
                                height: "100dvh",
                                duration: 1,
                                ease: "power4.out",
                                //  onComplete:()=>{
                                //     menu_container.classList.add('active')
                                //  }
                            }
                        );

                        menu_container.classList.add("active");
                        gsap.to(menu_container, {
                            opacity: 1,
                        });

                        setTimeout(() => {
                            timeline.to(menu_items_array, {
                                width: "100%",
                                duration: .8,
                                stagger: 0.2,
                                ease: "power4.out",
                            });
                            timeline.to(menu_buttons, {
                                top: "0",
                                duration: .8,
                                ease: "power4.out",
                                delay: -1,
                            });
                        }, timeout);

                        gsap.to(nav_footer, {
                            opacity: 1,
                            delay: 1.3
                        })

                        // nav.style.backgroundColor = '#141321e3'
                    } else {
                        menu_container.classList.remove("active");
                        gsap.to(menu_container, {
                            opacity: 0,
                        });

                        menu_items_array.forEach((items) => {
                            items.style.width = "0";
                        });

                        menu_buttons.forEach((items) => {
                            items.style.cssText = `
                top:200px
            `;
                        });

                        gsap.fromTo(
                            nav,
                            {
                                height: "100svh",
                            },
                            {
                                height: "83px",
                                duration: 1,
                                ease: "power4.out",
                            }
                        );

                        if (mobile_screen.matches) {
                            gsap.fromTo(
                                nav,
                                {
                                    height: "100dvh",
                                },
                                {
                                    height: "72px",
                                    duration: 1,
                                    ease: "power4.out",
                                    onComplete: () => {
                                        if (mobile_screen.matches) {
                                            gsap.to(nav_footer, {
                                                opacity: 0
                                            })
                                        }
                                    }
                                }
                            );
                        }

                        // nav.style.backgroundColor = '#14132185'
                    }
                };

                var works_bool = true;
                const menu_tl = gsap.timeline();
                works_bool = !works_bool;

                arrow_menu_array.forEach(items => {
                    items.onclick = () => {
                        menu_arrow.classList.toggle('toggle_arrow')


                        if (works_bool = !works_bool) {

                            menu_tl.to(menu_buttons, {
                                top: '200px',
                                duration: .8,
                                ease: 'power4.in',
                            })

                            menu_tl.to(works_menu, {
                                opacity: 1,
                                top: '0',
                                duration: .8,
                                ease: 'power4.out',
                            })

                        } else {
                            menu_tl.to(works_menu, {
                                opacity: 0,
                                top: '200px',
                                duration: .8,
                                ease: 'power4.in',
                            })

                            menu_tl.to(menu_buttons, {
                                top: '0',
                                duration: .8,
                                ease: 'power4.out',
                            })
                        }
                    }
                })

                //End of responsive nav codes...

                function MainFunction() {




                    const vizUrl_array = [
                        "d/ozw1YrOj-pY?si=kZonmd3fNp6PvN6q&amp;",
                        "d/m1d2jnzRwgA?si=LLzLwemK7IS0XJ85&amp;",
                        "d/M7eqO0Kb0JY?si=67ZmDVNHxuK_vWQE&amp;",
                        "d/n58dSZIgQB0?si=TKuzg8wgsH_RNTIU&amp;",

                        "d/8zkx8R_lNWU?si=68-rzQ_UcA6xv4pi&amp;",
                        "d/1AHv-xVkF8k?si=zwOIrFMYIppEOYxI&amp;",
                        "d/I6wzhp4g2Cw?si=BU9WV8-6ed_h1kfu&amp;",
                        "d/gqm6vwoHzFI?si=6rBQSxMPfEV5Nihq&amp;",
                        "d/iiooBnAgXOU?si=uPq3wYigHUQSKMhf&amp;",
                        "d/7zq1xQ1vKVw?si=3FDFUigGLp4syVtc&amp;",

                        "d/CDWVeVZa-DA?si=C7Spa6k7F34PQDi9&amp;",
                        "d/CDlowo-jes0?si=yEixi-7nWCZLqO2m&amp;",
                        "d/1j8WzAFcsd0?si=bYxJiTHJSrGI0inb&amp;",
                        "d/jm-7qVrU6Po?si=swM3cbAvUqgI2DSO&amp;",
                    ];

                    const anim_titles = [
                        "Kwesi Arthur - 4lyfe",
                        "Joeboy - Body & Soul",
                        "Joeboy - Loose Ya",
                        "Ric Hassani - Thunder Fire You",

                        "Joeboy - Concerning",
                        "Yaadman fka Yung L & Wizkid - Eve Bounce (Remix)",
                        "Joeboy - Baby",
                        "Mr Eazi ft Simi - Surrender",

                        "Joeboy - Lonely ",
                        "Reekado Banks - Happy yourself",
                        "Young Jonn, Tiwa Savage - Let Them Know ft. Joeboy",
                        "Akwaboah - One Day",
                        "Joeboy - Focus ",
                        "Joeboy - Call",
                    ];

                    const works = document.querySelector(".works");

                    const viz_array = Object.keys(visualizers);
                    const new_array = [];

                    const toggleDarkMode = () => {
                        const toggle_mode = document.querySelector(".toggle_mode");
                        var toggle = true;
                        var key = "toggle_key";
                        toggle = !toggle;
                        const local_toggle = localStorage.getItem(key);

                        toggle_mode.onclick = () => {
                            if ((toggle = !toggle)) {
                                localStorage.setItem(key, toggle);
                                document.body.classList.add("darkmode");
                            } else {
                                localStorage.setItem(key, toggle);
                                document.body.classList.remove("darkmode");
                            }
                        };

                        if (local_toggle === "true") {
                            document.body.classList.add("darkmode");
                        }

                        if (local_toggle === "false") {
                            document.body.classList.remove("darkmode");
                        }
                    };
                    toggleDarkMode();

                    //Vizualizer animations appending into DOM

                    viz_array.sort((a, b) => {
                        const figA = a.replace("viz_", "");
                        const figB = b.replace("viz_", "");

                        const numA = Number(figA);
                        const numB = Number(figB);

                        return numA - numB;
                    });

                    for (let i = 0; i < viz_array.length; i += 2) {
                        const obj = {
                            key_one: {
                                image_link: viz_array[i],
                                url: vizUrl_array[i],
                                anim_title: anim_titles[i],
                            },
                            key_two: {
                                image_link: viz_array[i + 1],
                                url: vizUrl_array[i + 1],
                                anim_title: anim_titles[i + 1],
                            },
                        };

                        new_array.push(obj);
                    }

                    new_array.forEach((items) => {
                        const vizualizer_container = document.createElement("div");
                        vizualizer_container.classList = "vizualizer_container";

                        const id_1 = items.key_one.image_link.replace("viz_", "");
                        const id_2 = items.key_two.image_link.replace("viz_", "");




                        if (mobile_screen.matches) {
                            const template = `
                            <div class="vizualizer">
                
                                <div class="viz_img_container" >
                                
                                <img class='viz_image' src="${visualizers[items.key_one.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_1}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 <div class="glow"  data-scroll data-scroll-speed="0">
                                    <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                
                             </div>
                
                             <div class="vizualizer">
                
                                <div class="viz_img_container"
                                 data-scroll 
                                 data-scroll-speed="0">
                
                                <img class='viz_image' src="${visualizers[items.key_two.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_2}  
                                 data-scroll 
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat  
                                 data-url=${items.key_two.url}
                                 data-title="${items.key_two.anim_title}"/>
                
                                 <div class="glow"
                                 data-scroll  
                                 data-scroll-speed="0"
                                 >
                                 <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                             </div>
                        `;

                            vizualizer_container.innerHTML = template;
                            works.appendChild(vizualizer_container);
                        } else {
                            const template = `
                            <div class="vizualizer">
                
                                <div class="viz_img_container" >
                                
                                <img class='viz_image' src="${visualizers[items.key_one.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_1}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 <div class="glow"  data-scroll data-scroll-speed="0">
                                    <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                
                             </div>
                
                             <div class="vizualizer">
                
                                <div class="viz_img_container"
                                 data-scroll 
                                 data-scroll-speed="0.15">
                
                                <img class='viz_image' src="${visualizers[items.key_two.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_2}  
                                 data-scroll 
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat  
                                 data-url=${items.key_two.url}
                                 data-title="${items.key_two.anim_title}"/>
                
                                 <div class="glow"
                                 data-scroll  
                                 data-scroll-speed="0"
                                 >
                                 <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                             </div>
                        `;

                            vizualizer_container.innerHTML = template;
                            works.appendChild(vizualizer_container);
                        }


                    });

                    //Click to play video function begins here...

                    const iframe_container = document.querySelector(".iframe_container");
                    const iframe = document.querySelector("iframe");

                    document.addEventListener("click", (e) => {
                        const target = e.target.closest(".glow");

                        if (target) {
                            const viz = target.previousElementSibling;
                            const data_url = viz.getAttribute("data-url");
                            const iframe_url =
                                "https://www.youtube.com/embed/a&amp;controls=1";

                            iframe_container.style.cssText = `
                                display:block;
                            `;

                            var new_url = iframe_url.replace(/d(.*?)&/, `${data_url}`);
                            iframe.src = new_url;
                        }
                    });

                    const close = document.querySelector(".close");

                    close.onclick = () => {
                        iframe_container.style.display = "none";
                        iframe.src = "about:blank";
                        iframe.contentWindow.postMessage(
                            '{"event":"command","func":"stopVideo","args":""}',
                            "*"
                        );
                        history.go(-1);
                    };

                    setTimeout(() => {
                        const glow = document.querySelectorAll(".glow");


                        glow.forEach((items, index) => {
                            // items.style.height = imageHeight.height + "px";
                        });

                        //Card 3d hover effect

                        let cardSize;
                        glow.forEach((items) => {
                            const rotateToMouse = (e) => {
                                const mouseX = e.clientX;
                                const mouseY = e.clientY;
                                const leftX = mouseX - cardSize.x;
                                const topY = mouseY - cardSize.y;

                                const center = {
                                    x: leftX - cardSize.width / 2,
                                    y: topY - cardSize.height / 2,
                                };

                                const distance = Math.sqrt(center.x ** 0.5 + center.y ** 2);

                                // allVizImages.forEach(cards=>{

                                //     cards.style.transform = `
                                //     rotate3d(
                                //     ${center.y / 100},
                                //     ${center.x / 100},
                                //     0,
                                //     ${Math.log(distance) * 2}deg
                                //     )
                                //      `

                                // })

                                items.style.backgroundImage = `
                                radial-gradient(
                                  circle at
                                  ${center.x * 2 + cardSize.width / 2}px
                                  ${center.y * 2 + cardSize.height / 2}px,
                                  #ffffff55,
                                  #0000000f
                                )
                              `;

                                items.style.cursor = "pointer";
                            };

                            items.addEventListener("mouseenter", () => {
                                cardSize = items.getBoundingClientRect();
                                document.addEventListener("mousemove", rotateToMouse);
                            });

                            items.addEventListener("mouseleave", () => {
                                document.removeEventListener("mousemove", rotateToMouse);
                                items.style.transform = "";
                                items.style.backgroundImage = "";
                            });
                        });

                        //Set intitial animation values with gsap.set



                        const allVizImages = document.querySelectorAll(".viz_image");

                        allVizImages.forEach((items) => {
                            const viz_id = items.getAttribute("data-id");
                            if (mobile_screen.matches) {
                                if (viz_id > 1) {
                                    const card_parent = items.parentElement.parentElement;
                                    gsap.set(card_parent, {
                                        //test value
                                        opacity: 1,
                                        scale: 0.85,
                                        y: 0,
                                    });
                                }
                            } else {
                                if (viz_id > 1) {
                                    const card_parent = items.parentElement.parentElement;
                                    gsap.set(card_parent, {
                                        //test value
                                        opacity: 1,
                                        scale: 0.85,
                                        y: 150,
                                    });
                                }
                            }
                        });

                        //Scroll trigger functions begin here...
                        setTimeout(() => {
                            window.addEventListener("works_trigger", (e) => {
                                const target = e.detail;
                                const in_view = target.target;
                                const card_parent = in_view.parentElement.parentElement;

                                // anim_title_h3.textContent = target_title
                                // current_img.setAttribute('src', target_img)
                                // anim_title.style.display = 'block'

                                gsap.to(card_parent, {
                                    duration: 1.5,
                                    opacity: 1,
                                    scale: 1,
                                    y: 0,
                                    ease: "power4.out",
                                });
                            });
                        }, 500)


                        // Function to create an IntersectionObserver

                        if (mobile_screen.matches) {
                            const options = {
                                root: null, // Use the viewport as the container
                                rootMargin: "-50% 0% -50% 0%", // No margin around the root
                                threshold: 0, // Trigger when 10% of the element is visible
                            };

                            const createObserver = () => {
                                const mobile_anim_title_h3 = document.querySelector(".mobile_anim_title h3");
                                const current_image = document.querySelector(".current_image img");

                                const observer = new IntersectionObserver((entries, observer) => {
                                    entries.forEach((entry) => {
                                        if (entry.isIntersecting) {

                                            const target_title = entry.target.getAttribute("data-title");
                                            const target_img = entry.target.getAttribute("src");
                                            const play_button = entry.target.nextElementSibling.querySelector('img')

                                            console.log(play_button)

                                            // anim_title_h3.textContent = target_title;
                                            mobile_anim_title_h3.textContent = target_title;
                                            current_image.src = target_img

                                            var inview_target = entry.target.parentElement.parentElement
                                            console.log(inview_target)

                                            gsap.to(inview_target, {
                                                duration: 1,
                                                opacity: 1,
                                                scale: 1,
                                                y: 0,
                                                ease: "power4.out",
                                            });

                                            play_button.style.opacity = 1
                                            // anim_title.style.display = "block";

                                            // Perform actions when the element is in view
                                            // entry.target.classList.add('in-view'); // Example: add a class

                                            // Optionally, unobserve the element if you only want to trigger once
                                            //observer.unobserve(entry.target);
                                        } else {
                                            const play_button = entry.target.nextElementSibling.querySelector('img')
                                            play_button.style.opacity = 0
                                            // Perform actions when the element is out of view
                                            // entry.target.classList.remove('in-view'); // Example: remove a class
                                        }
                                    });


                                }, options);

                                // Observe all elements with the class 'scroll-item'
                                allVizImages.forEach((items) => {
                                    observer.observe(items);
                                });
                            }

                            createObserver();
                        }

                        function createObserver() {
                            const options = {
                                root: null, // Use the viewport as the container
                                rootMargin: "0%", // No margin around the root
                                threshold: .1, // Trigger when 10% of the element is visible
                            };



                            const anim_title = document.querySelector(".anim_title");
                            const anim_title_h3 = document.querySelector(".anim_title h3");
                            const current_img = document.querySelector(".current_img");

                            const observer = new IntersectionObserver((entries, observer) => {
                                entries.forEach((entry) => {
                                    if (entry.isIntersecting) {

                                        const target_title =
                                            entry.target.getAttribute("data-title");
                                        const target_img = entry.target.getAttribute("src");

                                        anim_title_h3.textContent = target_title;
                                        current_img.setAttribute("src", target_img);
                                        anim_title.style.display = "block";



                                        // Perform actions when the element is in view
                                        // entry.target.classList.add('in-view'); // Example: add a class

                                        // Optionally, unobserve the element if you only want to trigger once
                                        //observer.unobserve(entry.target);
                                    } else {

                                        // Perform actions when the element is out of view
                                        // entry.target.classList.remove('in-view'); // Example: remove a class
                                    }
                                });


                            }, options);

                            // Observe all elements with the class 'scroll-item'
                            allVizImages.forEach((items) => {
                                observer.observe(items);
                            });
                        }
                        createObserver();






                        //Dom load setTimout Function ends here...
                    }, 500);

                    setTimeout(() => {

                        //Gradient backgroudn height adaptation functions begins here...
                        const gradient_background = document.querySelector(
                            ".gradient_background"
                        );
                        const works_container = document.querySelector(".works_container");

                        const works_height = works_container.getBoundingClientRect().height;
                        gradient_background.style.height = works_height + "px";

                    }, 500)

                    //Reesponive Height for mobile screens

                    setTimeout(() => {
                        const images = document.querySelectorAll('img')
                        var loadedCount = 0

                        images.forEach(image => {
                            if (image.complete) {
                                loadedCount++
                            } else {
                                image.addEventListener('load', () => {
                                    loadedCount++

                                    if (loadedCount === images.length) {
                                        // alert('they ones that didnt load have loaded')

                                        if (mobile_screen.matches) {

                                            //Gradient backgroudn height adaptation functions begins here...
                                            const gradient_background = document.querySelector(
                                                ".gradient_background"
                                            );
                                            const works_container = document.querySelector(".works_container");

                                            const works_height = works_container.getBoundingClientRect().height;
                                            gradient_background.style.height = works_height + "px";

                                            const layout = document.querySelector('.layout')
                                            layout.style.height = works_height + 100 + 'px'
                                            console.log(layout)

                                            works_container.style.height = works_height + 100 + 'px'

                                            gsap.to(layout, {
                                                display: 'block',
                                                opacity: 1
                                            })
                                        }
                                    }
                                })
                            }

                            if (loadedCount === images.length) {

                                if (mobile_screen.matches) {

                                    //Gradient backgroudn height adaptation functions begins here...
                                    const gradient_background = document.querySelector(
                                        ".gradient_background"
                                    );
                                    const works_container = document.querySelector(".works_container");

                                    const works_height = works_container.getBoundingClientRect().height;
                                    gradient_background.style.height = works_height + "px";

                                    const layout = document.querySelector('.layout')
                                    layout.style.height = works_height + 100 + 'px'
                                    console.log(layout)

                                    works_container.style.height = works_height + 100 + 'px'

                                    gsap.to(layout, {
                                        display: 'block',
                                        opacity: 1
                                    })
                                }

                            }
                        })

                    }, 100)




                    window.addEventListener("contactTrigger", () => {
                        const challenge_img = document.querySelector(".challenge_img");
                        const challenge_content =
                            document.querySelector("#challenge_content");
                        const contact_img = document.querySelector(".contact_img");
                        const contact_content = document.querySelector("#contact_content");
                        const footer_arr = [
                            challenge_img,
                            challenge_content,
                            contact_img,
                            contact_content,
                        ];

                        gsap.to(footer_arr, {
                            alpha: 1,
                            stagger: 0.2,
                            duration: 1.5,
                            ease: "power4.out",
                        });
                    });

                    // const iframe = document.querySelector('iframe')
                    var string_one = iframe.getAttribute("src");
                    const text = "d/bobby&amp;";
                    var new_url = string_one.replace(/d(.*?)&/, `${text}`);


                    locomotiveScroll.start();

                    //End of MainFunction//
                }

                try {
                    MainFunction();
                } catch (error) { }
            },
        },
        {
            namespace: "music_videos",
            afterEnter() {
                if (mobile_screen.matches) {
                    setTimeout(() => {
                        initScroll();
                        let scrollX = 0
                        let scrollY = 0

                        barba.hooks.leave(() => {
                            scrollX = barba.history.current.scroll.x;
                            scrollY = barba.history.current.scroll.y;
                        });

                        // then later in the code...
                        window.scrollTo(scrollX, scrollY);



                    }, 500);
                } else {
                    setTimeout(() => {
                        initScroll();
                        scroll.scrollTo(0, { duration: 3, easeing: [0.25, 0.0, 0.35, 1.0] });
                        // resolve();
                    }, 500); // Adjust delay as needed
                }

               //Responsive Navbar
               const nav = document.querySelector("nav");
               const menu_container = document.querySelector(".menu_container");
               let lastScrollPosition = 0;

               var cursorBool = false;

               menu_container.addEventListener("mouseenter", () => {
                   cursorBool = true;
               });

               nav.addEventListener("mouseleave", () => {
                   cursorBool = false;
               });

               menu_container.addEventListener("touchmove", () => {
                   cursorBool = true;
               });

               // nav.addEventListener("touchend", () => {
               //     cursorBool = false;
               // });

               
                   if (mobile_screen.matches) {
                       window.addEventListener("scroll", () => {
                           if (cursorBool == true) {
                               return;
   
                           }
   
                           nav.classList.remove("add_nav");
                       })
                      

                   } else {
                       window.addEventListener("scroll", () => {
                           if (cursorBool == true) {
                               return;
   
                           }
   
                           const currentScrollPosition = window.scrollY;
   
                           if (currentScrollPosition > lastScrollPosition) {
                               // Scrolling down
   
                               nav.classList.add("add_nav");
                           } else if (currentScrollPosition < lastScrollPosition) {
                               // Scrolling up
   
                               nav.classList.remove("add_nav");
                           }
   
                           // Update the last scroll position
                           lastScrollPosition = currentScrollPosition;
                       })
                    
                   }

              


               //Menu pane codes here...
               const menu = document.querySelector(".menu_trigger");

               const menu_items = document.querySelector(".menu_items");
               const menu_items_array = [...menu_items.children];
               const menu_buttons = menu_items.querySelectorAll(".main_menu");
               const works_menu = menu_items.querySelectorAll(".works_menu");
               const menu_arrow = document.querySelector(".menu_arrow");
               const arrow_menu_array = document.querySelectorAll('.menu_arrow, .arrow_menu')
               const timeout = 500;
               var toggle_bool = true;
               const timeline = gsap.timeline();

               toggle_bool = !toggle_bool;

               menu.onclick = () => {
                   //nav.classList.toggle('toggle_nav')

                   if ((toggle_bool = !toggle_bool)) {
                       gsap.fromTo(
                           nav,
                           {
                               height: "auto",
                           },
                           {
                               height: "100dvh",
                               duration: 1,
                               ease: "power4.out",
                               //  onComplete:()=>{
                               //     menu_container.classList.add('active')
                               //  }
                           }
                       );

                       menu_container.classList.add("active");
                       gsap.to(menu_container, {
                           opacity: 1,
                       });

                       setTimeout(() => {
                           timeline.to(menu_items_array, {
                               width: "100%",
                               duration: .8,
                               stagger: 0.2,
                               ease: "power4.out",
                           });
                           timeline.to(menu_buttons, {
                               top: "0",
                               duration: .8,
                               ease: "power4.out",
                               delay: -1,
                           });
                       }, timeout);

                       gsap.to(nav_footer, {
                           opacity: 1,
                           delay: 1.3
                       })

                       // nav.style.backgroundColor = '#141321e3'
                   } else {
                       menu_container.classList.remove("active");
                       gsap.to(menu_container, {
                           opacity: 0,
                       });

                       menu_items_array.forEach((items) => {
                           items.style.width = "0";
                       });

                       menu_buttons.forEach((items) => {
                           items.style.cssText = `
               top:200px
           `;
                       });

                       gsap.fromTo(
                           nav,
                           {
                               height: "100svh",
                           },
                           {
                               height: "83px",
                               duration: 1,
                               ease: "power4.out",
                           }
                       );

                       if (mobile_screen.matches) {
                           gsap.fromTo(
                               nav,
                               {
                                   height: "100dvh",
                               },
                               {
                                   height: "72px",
                                   duration: 1,
                                   ease: "power4.out",
                                   onComplete: () => {
                                       if (mobile_screen.matches) {
                                           gsap.to(nav_footer, {
                                               opacity: 0
                                           })
                                       }
                                   }
                               }
                           );
                       }

                       // nav.style.backgroundColor = '#14132185'
                   }
               };

               var works_bool = true;
               const menu_tl = gsap.timeline();
               works_bool = !works_bool;

               arrow_menu_array.forEach(items => {
                   items.onclick = () => {
                       menu_arrow.classList.toggle('toggle_arrow')


                       if (works_bool = !works_bool) {

                           menu_tl.to(menu_buttons, {
                               top: '200px',
                               duration: .8,
                               ease: 'power4.in',
                           })

                           menu_tl.to(works_menu, {
                               opacity: 1,
                               top: '0',
                               duration: .8,
                               ease: 'power4.out',
                           })

                       } else {
                           menu_tl.to(works_menu, {
                               opacity: 0,
                               top: '200px',
                               duration: .8,
                               ease: 'power4.in',
                           })

                           menu_tl.to(menu_buttons, {
                               top: '0',
                               duration: .8,
                               ease: 'power4.out',
                           })
                       }
                   }
               })

               //End of responsive nav codes...

                function MainFunction() {
                    const vizUrl_array = [
                        "d/I2Dq_vBT33I?si=znvm5kIs-2UyFHq-&amp;",
                        "d/n3hSeu2NYXU?si=FA5ZmF2ORiactywG&amp;",
                        "d/LjWLrtI2ZJ0?si=NhgN9Ksi6WfMUqYv&amp;",
                        "d/k3Au4YjqL-4?si=N8mD8KHjL498yQTF&amp;",
                        "d/1WV4goDZGE4?si=JC76rKscFZ6yszB4&amp;",
                        "d/tQ-yljcrqiA?si=IXKNHaFETcNP0-QD&amp;",
                        "d/GcvVOT_n7q8?si=loU2T1v4GTcuLsK5&amp;",
                        "d/ni4FVphHynY?si=K8x-7AqtE3CjTgG_&amp;",
                        "d/flIzSu29YzU?si=8aJwM_b24D7xFVpq&amp;",
                        "d/3RTH3Rgv6Ro?si=rbkCBrmi71LaUBbs&amp;",
                    ];

                    const anim_titles = [
                        "Yemi Alade - Begging",
                        "Juls - Gwarn featuring Burna Boy ",
                        "Joeboy - Adenuga ft. Qing Madi",
                        "Mr Eazi - Jamboree (feat. Tekno)",
                        "Yemi Alade - My Man ",
                        "Akwaboah - Wo Pɛ W'adiɛ",
                        "Diamond Platnumz - JeJe",
                        "Ric Hassani - My Kind Of Woman ft. Sauti Sol",
                        "Ric Hassani - Angel ft. Nicky Jam",
                        "Joeboy - Wetin Be Love (feat. CKay) ",
                    ];

                    const works = document.querySelector(".works");

                    const viz_array = Object.keys(music_videos);
                    const new_array = [];
                    const toggleDarkMode = () => {
                        const toggle_mode = document.querySelector(".toggle_mode");
                        var toggle = true;
                        var key = "toggle_key";
                        toggle = !toggle;
                        const local_toggle = localStorage.getItem(key);

                        toggle_mode.onclick = () => {
                            if ((toggle = !toggle)) {
                                localStorage.setItem(key, toggle);
                                document.body.classList.add("darkmode");
                            } else {
                                localStorage.setItem(key, toggle);
                                document.body.classList.remove("darkmode");
                            }
                        };

                        if (local_toggle === "true") {
                            document.body.classList.add("darkmode");
                        }

                        if (local_toggle === "false") {
                            document.body.classList.remove("darkmode");
                        }
                    };
                    toggleDarkMode();

                    //Vizualizer animations appending into DOM

                    viz_array.sort((a, b) => {
                        const figA = a.replace("music_vid_", "");
                        const figB = b.replace("music_vid_", "");

                        const numA = Number(figA);
                        const numB = Number(figB);

                        return numA - numB;
                    });


                    for (let i = 0; i < viz_array.length; i += 2) {
                        const obj = {
                            key_one: {
                                image_link: viz_array[i],
                                url: vizUrl_array[i],
                                anim_title: anim_titles[i],
                            },
                            key_two: {
                                image_link: viz_array[i + 1],
                                url: vizUrl_array[i + 1],
                                anim_title: anim_titles[i + 1],
                            },
                        };

                        new_array.push(obj);
                    }

                    new_array.forEach((items) => {
                        const vizualizer_container = document.createElement("div");
                        vizualizer_container.classList = "vizualizer_container";

                        const id_1 = items.key_one.image_link.replace("music_vid_", "");
                        const id_2 = items.key_two.image_link.replace("music_vid_", "");

                        if (mobile_screen.matches) {
                            const template = `
                            <div class="vizualizer">
                
                                <div class="viz_img_container" >
                                
                                <img class='music_image' src="${music_videos[items.key_one.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_1}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 <div class="glow"  data-scroll data-scroll-speed="0">
                                    <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                
                             </div>
                
                             <div class="vizualizer">
                
                                <div class="viz_img_container"
                                 data-scroll 
                                 data-scroll-speed="0">
                
                                <img class='music_image' src="${music_videos[items.key_two.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_2}  
                                 data-scroll 
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat  
                                 data-url=${items.key_two.url}
                                 data-title="${items.key_two.anim_title}"/>
                
                                 <div class="glow"
                                 data-scroll  
                                 data-scroll-speed="0"
                                 >
                                 <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                             </div>
                        `;

                            vizualizer_container.innerHTML = template;
                            works.appendChild(vizualizer_container);
                        } else {
                            const template = `
                            <div class="vizualizer">
                
                                <div class="viz_img_container" >
                                
                                <img class='music_image' src="${music_videos[items.key_one.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_1}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 <div class="glow"  data-scroll data-scroll-speed="0">
                                    <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                
                             </div>
                
                             <div class="vizualizer">
                
                                <div class="viz_img_container"
                                 data-scroll 
                                 data-scroll-speed="0.15">
                
                                <img class='music_image' src="${music_videos[items.key_two.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_2}  
                                 data-scroll 
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat  
                                 data-url=${items.key_two.url}
                                 data-title="${items.key_two.anim_title}"/>
                
                                 <div class="glow"
                                 data-scroll  
                                 data-scroll-speed="0"
                                 >
                                 <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                             </div>
                        `;

                            vizualizer_container.innerHTML = template;
                            works.appendChild(vizualizer_container);
                        }



                        // const template = `
                        //     <div class="vizualizer">

                        //         <div class="viz_img_container" >
                        //         <img class='music_image' src="${music_videos[items.key_one.image_link]
                        //     }" alt="poka studios vizualizers"
                        //          data-id=${id_1}  
                        //          data-scroll 
                        //          data-scroll-speed="0"
                        //          data-scroll-position="start,end"
                        //          data-scroll-offset="10%,25%"
                        //          data-scroll-call="music_trigger"
                        //          data-scroll-repeat 
                        //          data-url=${items.key_one.url}
                        //          data-title="${items.key_one.anim_title}"/>
                        //          <div class="glow"  data-scroll data-scroll-speed="0">
                        //             <img src="${play_button}" alt="poka studio - play button">
                        //          </div>
                        //         </div>



                        //      </div>

                        //      <div class="vizualizer">

                        //         <div class="viz_img_container"
                        //          data-scroll 
                        //          data-scroll-speed="0.15">

                        //         <img class='music_image' src="${music_videos[items.key_two.image_link]
                        //     }" alt="poka studios vizualizers"
                        //          data-id=${id_2}  
                        //          data-scroll 
                        //          data-scroll-position="start,end"
                        //          data-scroll-offset="10%,50%"
                        //          data-scroll-call="music_trigger"
                        //          data-scroll-repeat  
                        //          data-url=${items.key_two.url}
                        //          data-title="${items.key_two.anim_title}"/>

                        //          <div class="glow"
                        //          data-scroll  
                        //          data-scroll-speed="0"
                        //          >
                        //          <img src="${play_button}" alt="poka studio - play button">
                        //          </div>
                        //         </div>


                        //      </div>
                        // `;

                        // vizualizer_container.innerHTML = template;
                        // works.appendChild(vizualizer_container);
                    });

                    //Click to play video function begins here...

                    const iframe_container = document.querySelector(".iframe_container");
                    const iframe = document.querySelector("iframe");

                    document.addEventListener("click", (e) => {
                        const target = e.target.closest(".glow");

                        if (target) {
                            const viz = target.previousElementSibling;
                            const data_url = viz.getAttribute("data-url");
                            const iframe_url =
                                "https://www.youtube.com/embed/a&amp;controls=1";

                            iframe_container.style.cssText = `
                                display:block;
                            `;

                            var new_url = iframe_url.replace(/d(.*?)&/, `${data_url}`);
                            iframe.src = new_url;

                        }
                    });

                    const close = document.querySelector(".close");

                    close.onclick = () => {
                        iframe_container.style.display = "none";
                        iframe.src = "about:blank";
                        iframe.contentWindow.postMessage(
                            '{"event":"command","func":"stopVideo","args":""}',
                            "*"
                        );


                        history.go(-1);
                    };

                    setTimeout(() => {
                        const glow = document.querySelectorAll(".glow");
                        const viz_image = document.querySelector(".music_image");
                        const imageHeight = viz_image.getBoundingClientRect();


                        glow.forEach((items, index) => {
                            // items.style.height = imageHeight.height + "px";
                        });

                        //Card 3d hover effect

                        let cardSize;
                        glow.forEach((items) => {
                            const rotateToMouse = (e) => {
                                const mouseX = e.clientX;
                                const mouseY = e.clientY;
                                const leftX = mouseX - cardSize.x;
                                const topY = mouseY - cardSize.y;

                                const center = {
                                    x: leftX - cardSize.width / 2,
                                    y: topY - cardSize.height / 2,
                                };

                                const distance = Math.sqrt(center.x ** 0.5 + center.y ** 2);

                                // allVizImages.forEach(cards=>{

                                //     cards.style.transform = `
                                //     rotate3d(
                                //     ${center.y / 100},
                                //     ${center.x / 100},
                                //     0,
                                //     ${Math.log(distance) * 2}deg
                                //     )
                                //      `

                                // })

                                items.style.backgroundImage = `
                                radial-gradient(
                                  circle at
                                  ${center.x * 2 + cardSize.width / 2}px
                                  ${center.y * 2 + cardSize.height / 2}px,
                                  #ffffff55,
                                  #0000000f
                                )
                              `;

                                items.style.cursor = "pointer";
                            };

                            items.addEventListener("mouseenter", () => {
                                cardSize = items.getBoundingClientRect();
                                document.addEventListener("mousemove", rotateToMouse);
                            });

                            items.addEventListener("mouseleave", () => {
                                document.removeEventListener("mousemove", rotateToMouse);
                                items.style.transform = "";
                                items.style.backgroundImage = "";
                            });
                        });

                        //Set intitial animation values with gsap.set



                        const allVizImages = document.querySelectorAll(".music_image");

                        allVizImages.forEach((items) => {
                            const viz_id = items.getAttribute("data-id");



                            if (mobile_screen.matches) {
                                if (viz_id > 1) {
                                    const card_parent = items.parentElement.parentElement;
                                    gsap.set(card_parent, {
                                        //test value
                                        opacity: 1,
                                        scale: 0.85,
                                        y: 0,
                                    });
                                }
                            } else {
                                if (viz_id > 1) {
                                    const card_parent = items.parentElement.parentElement;
                                    gsap.set(card_parent, {
                                        //test value
                                        opacity: 1,
                                        scale: 0.85,
                                        y: 150,
                                    });
                                }
                            }
                        });

                        //Scroll trigger functions begin here...
                        setTimeout(() => {
                            window.addEventListener("works_trigger", (e) => {
                                const target = e.detail;
                                const in_view = target.target;
                                const card_parent = in_view.parentElement.parentElement;

                                // anim_title_h3.textContent = target_title
                                // current_img.setAttribute('src', target_img)
                                // anim_title.style.display = 'block'

                                gsap.to(card_parent, {
                                    duration: 1.5,
                                    opacity: 1,
                                    scale: 1,
                                    y: 0,
                                    ease: "power4.out",
                                });
                            });
                        }, 500)


                        // Function to create an IntersectionObserver

                        if (mobile_screen.matches) {
                            const options = {
                                root: null, // Use the viewport as the container
                                rootMargin: "-50% 0% -50% 0%", // No margin around the root
                                threshold: 0, // Trigger when 10% of the element is visible
                            };

                            const createObserver = () => {
                                const mobile_anim_title_h3 = document.querySelector(".mobile_anim_title h3");
                                const current_image = document.querySelector(".current_image img");

                                const observer = new IntersectionObserver((entries, observer) => {
                                    entries.forEach((entry) => {
                                        if (entry.isIntersecting) {

                                            const target_title = entry.target.getAttribute("data-title");
                                            const target_img = entry.target.getAttribute("src");
                                            const play_button = entry.target.nextElementSibling.querySelector('img')

                                            console.log(play_button)

                                            // anim_title_h3.textContent = target_title;
                                            mobile_anim_title_h3.textContent = target_title;
                                            current_image.src = target_img

                                            var inview_target = entry.target.parentElement.parentElement
                                            console.log(inview_target)

                                            gsap.to(inview_target, {
                                                duration: 1,
                                                opacity: 1,
                                                scale: 1,
                                                y: 0,
                                                ease: "power4.out",
                                            });

                                            play_button.style.opacity = 1
                                            // anim_title.style.display = "block";

                                            // Perform actions when the element is in view
                                            // entry.target.classList.add('in-view'); // Example: add a class

                                            // Optionally, unobserve the element if you only want to trigger once
                                            //observer.unobserve(entry.target);
                                        } else {
                                            const play_button = entry.target.nextElementSibling.querySelector('img')
                                            play_button.style.opacity = 0
                                            // Perform actions when the element is out of view
                                            // entry.target.classList.remove('in-view'); // Example: remove a class
                                        }
                                    });


                                }, options);

                                // Observe all elements with the class 'scroll-item'
                                allVizImages.forEach((items) => {
                                    observer.observe(items);
                                });
                            }

                            createObserver();
                        }

                        function createObserver() {
                            const options = {
                                root: null, // Use the viewport as the container
                                rootMargin: "0%", // No margin around the root
                                threshold: .1, // Trigger when 10% of the element is visible
                            };



                            const anim_title = document.querySelector(".anim_title");
                            const anim_title_h3 = document.querySelector(".anim_title h3");
                            const current_img = document.querySelector(".current_img");

                            const observer = new IntersectionObserver((entries, observer) => {
                                entries.forEach((entry) => {
                                    if (entry.isIntersecting) {

                                        const target_title =
                                            entry.target.getAttribute("data-title");
                                        const target_img = entry.target.getAttribute("src");

                                        anim_title_h3.textContent = target_title;
                                        current_img.setAttribute("src", target_img);
                                        anim_title.style.display = "block";



                                        // Perform actions when the element is in view
                                        // entry.target.classList.add('in-view'); // Example: add a class

                                        // Optionally, unobserve the element if you only want to trigger once
                                        //observer.unobserve(entry.target);
                                    } else {

                                        // Perform actions when the element is out of view
                                        // entry.target.classList.remove('in-view'); // Example: remove a class
                                    }
                                });


                            }, options);

                            // Observe all elements with the class 'scroll-item'
                            allVizImages.forEach((items) => {
                                observer.observe(items);
                            });
                        }
                        createObserver();






                        //Dom load setTimout Function ends here...
                    }, 500);

                    //Reesponive Height for mobile screens

                    setTimeout(() => {
                        const images = document.querySelectorAll('img')
                        var loadedCount = 0

                        images.forEach(image => {
                            if (image.complete) {
                                loadedCount++
                            } else {
                                image.addEventListener('load', () => {
                                    loadedCount++

                                    if (loadedCount === images.length) {
                                        // alert('they ones that didnt load have loaded')

                                        if (mobile_screen.matches) {

                                            //Gradient backgroudn height adaptation functions begins here...
                                            const gradient_background = document.querySelector(
                                                ".gradient_background"
                                            );
                                            const works_container = document.querySelector(".works_container");

                                            const works_height = works_container.getBoundingClientRect().height;
                                            gradient_background.style.height = works_height + "px";

                                            const layout = document.querySelector('.layout')
                                            layout.style.height = works_height + 100 + 'px'
                                            console.log(layout)

                                            works_container.style.height = works_height + 100 + 'px'


                                        }
                                    }
                                })
                            }

                            if (loadedCount === images.length) {

                                if (mobile_screen.matches) {

                                    //Gradient backgroudn height adaptation functions begins here...
                                    const gradient_background = document.querySelector(
                                        ".gradient_background"
                                    );
                                    const works_container = document.querySelector(".works_container");

                                    const works_height = works_container.getBoundingClientRect().height;
                                    gradient_background.style.height = works_height + "px";

                                    const layout = document.querySelector('.layout')
                                    layout.style.height = works_height + 100 + 'px'
                                    console.log(layout)

                                    works_container.style.height = works_height + 100 + 'px'

                                    gsap.to(layout, {
                                        display: 'block',
                                        opacity: 1
                                    })
                                }

                            }
                        })

                    }, 100)

                    if (mobile_screen.matches) {

                        const layout = document.querySelector('.layout')

                        gsap.to(layout, {
                            display: 'block',
                            opacity: 1
                        })
                    }


                    //Gradient backgroudn height adaptation functions begins here...
                    const gradient_background = document.querySelector(
                        ".gradient_background"
                    );
                    const works_container = document.querySelector(".works_container");

                    const works_height = works_container.getBoundingClientRect().height;
                    gradient_background.style.height = works_height + "px";

                    

                    window.addEventListener("contactTrigger", () => {
                        const challenge_img = document.querySelector(".challenge_img");
                        const challenge_content =
                            document.querySelector("#challenge_content");
                        const contact_img = document.querySelector(".contact_img");
                        const contact_content = document.querySelector("#contact_content");
                        const footer_arr = [
                            challenge_img,
                            challenge_content,
                            contact_img,
                            contact_content,
                        ];

                        gsap.to(footer_arr, {
                            alpha: 1,
                            stagger: 0.2,
                            duration: 1.5,
                            ease: "power4.out",
                        });
                    });

                    // const iframe = document.querySelector('iframe')
                    var string_one = iframe.getAttribute("src");
                    const text = "d/bobby&amp;";
                    var new_url = string_one.replace(/d(.*?)&/, `${text}`);


                    locomotiveScroll.start();

                    //End of MainFunction//

                    
                }

                try {
                    MainFunction();
                } catch (error) { }
            },
        },
        {
            namespace: "shorts",
            afterEnter() {

                if (mobile_screen.matches) {
                    setTimeout(() => {
                        initScroll();
                        let scrollX = 0
                        let scrollY = 0

                        barba.hooks.leave(() => {
                            scrollX = barba.history.current.scroll.x;
                            scrollY = barba.history.current.scroll.y;
                        });

                        // then later in the code...
                        window.scrollTo(scrollX, scrollY);



                    }, 500);
                } else {
                    setTimeout(() => {
                        initScroll();
                        scroll.scrollTo(0, { duration: 3, easeing: [0.25, 0.0, 0.35, 1.0] });
                        // resolve();
                    }, 500); // Adjust delay as needed
                }


                //Responsive Navbar
                const nav = document.querySelector("nav");
                const menu_container = document.querySelector(".menu_container");
                let lastScrollPosition = 0;

                var cursorBool = false;

                menu_container.addEventListener("mouseenter", () => {
                    cursorBool = true;
                });

                nav.addEventListener("mouseleave", () => {
                    cursorBool = false;
                });

                menu_container.addEventListener("touchmove", () => {
                    cursorBool = true;
                });

                // nav.addEventListener("touchend", () => {
                //     cursorBool = false;
                // });

                window.addEventListener("scroll", () => {
                    if (mobile_screen.matches) {
                        if (cursorBool == true) {
                            return;

                        }

                        nav.classList.remove("add_nav");

                    } else {
                        if (cursorBool == true) {
                            return;

                        }

                        const currentScrollPosition = window.scrollY;

                        if (currentScrollPosition > lastScrollPosition) {
                            // Scrolling down

                            nav.classList.add("add_nav");
                        } else if (currentScrollPosition < lastScrollPosition) {
                            // Scrolling up

                            nav.classList.remove("add_nav");
                        }

                        // Update the last scroll position
                        lastScrollPosition = currentScrollPosition;
                    }

                });


                //Menu pane codes here...
                const menu = document.querySelector(".menu_trigger");

                const menu_items = document.querySelector(".menu_items");
                const menu_items_array = [...menu_items.children];
                const menu_buttons = menu_items.querySelectorAll(".main_menu");
                const works_menu = menu_items.querySelectorAll(".works_menu");
                const menu_arrow = document.querySelector(".menu_arrow");
                const arrow_menu_array = document.querySelectorAll('.menu_arrow, .arrow_menu')
                const timeout = 500;
                var toggle_bool = true;
                const timeline = gsap.timeline();

                toggle_bool = !toggle_bool;

                menu.onclick = () => {
                    //nav.classList.toggle('toggle_nav')

                    if ((toggle_bool = !toggle_bool)) {
                        gsap.fromTo(
                            nav,
                            {
                                height: "auto",
                            },
                            {
                                height: "100dvh",
                                duration: 1,
                                ease: "power4.out",
                                //  onComplete:()=>{
                                //     menu_container.classList.add('active')
                                //  }
                            }
                        );

                        menu_container.classList.add("active");
                        gsap.to(menu_container, {
                            opacity: 1,
                        });

                        setTimeout(() => {
                            timeline.to(menu_items_array, {
                                width: "100%",
                                duration: .8,
                                stagger: 0.2,
                                ease: "power4.out",
                            });
                            timeline.to(menu_buttons, {
                                top: "0",
                                duration: .8,
                                ease: "power4.out",
                                delay: -1,
                            });
                        }, timeout);

                        gsap.to(nav_footer, {
                            opacity: 1,
                            delay: 1.3
                        })

                        // nav.style.backgroundColor = '#141321e3'
                    } else {
                        menu_container.classList.remove("active");
                        gsap.to(menu_container, {
                            opacity: 0,
                        });

                        menu_items_array.forEach((items) => {
                            items.style.width = "0";
                        });

                        menu_buttons.forEach((items) => {
                            items.style.cssText = `
                 top:200px
             `;
                        });

                        gsap.fromTo(
                            nav,
                            {
                                height: "100svh",
                            },
                            {
                                height: "83px",
                                duration: 1,
                                ease: "power4.out",
                            }
                        );

                        if (mobile_screen.matches) {
                            gsap.fromTo(
                                nav,
                                {
                                    height: "100dvh",
                                },
                                {
                                    height: "72px",
                                    duration: 1,
                                    ease: "power4.out",
                                    onComplete: () => {
                                        if (mobile_screen.matches) {
                                            gsap.to(nav_footer, {
                                                opacity: 0
                                            })
                                        }
                                    }
                                }
                            );
                        }

                        // nav.style.backgroundColor = '#14132185'
                    }
                };

                var works_bool = true;
                const menu_tl = gsap.timeline();
                works_bool = !works_bool;

                arrow_menu_array.forEach(items => {
                    items.onclick = () => {
                        menu_arrow.classList.toggle('toggle_arrow')


                        if (works_bool = !works_bool) {

                            menu_tl.to(menu_buttons, {
                                top: '200px',
                                duration: .8,
                                ease: 'power4.in',
                            })

                            menu_tl.to(works_menu, {
                                opacity: 1,
                                top: '0',
                                duration: .8,
                                ease: 'power4.out',
                            })

                        } else {
                            menu_tl.to(works_menu, {
                                opacity: 0,
                                top: '200px',
                                duration: .8,
                                ease: 'power4.in',
                            })

                            menu_tl.to(menu_buttons, {
                                top: '0',
                                duration: .8,
                                ease: 'power4.out',
                            })
                        }
                    }
                })

                function MainFunction() {
                    const vizUrl_array = [
                        "d/2H5MPH90PtI?si=pmesczYlqup62FJx&amp;",
                        "d/CxRdXDG9nxU?si=iUGc4olO30U5BlBm&amp;",
                        "d/sIV2qgBfXwg?si=T1WP7Y6Rje86EGoP&amp;",
                        "d/FJreIHeUvVs?si=gy-GOhT9BWjSF4Ej&amp;",

                        "d/YDSbFczeYaM?si=qqA_y3Jf1dP22fSP&amp;",
                        "d/7I2KgPYEyOQ?si=xLPux75vIIXRlpB9&amp;",
                        "d/0Ii1iof8K4Y?si=dIscryXyEB-a7z7V&amp;",
                        "d/29IOZPnyKcs?si=u85Q6ElDMSssaoTC&amp;",
                        "d/iiooBnAgXOU?si=uPq3wYigHUQSKMhf&amp;",
                        "d/7zq1xQ1vKVw?si=3FDFUigGLp4syVtc&amp;",

                        "d/CDWVeVZa-DA?si=C7Spa6k7F34PQDi9&amp;",
                        "d/CDlowo-jes0?si=yEixi-7nWCZLqO2m&amp;",
                        "d/1j8WzAFcsd0?si=bYxJiTHJSrGI0inb&amp;",
                        "d/jm-7qVrU6Po?si=swM3cbAvUqgI2DSO&amp;",
                    ];

                    const anim_titles = [
                        "Together, Forever ❤️",
                        "Angel NobodyKnows. Y3 FAST!",
                        "The Redeemer",
                        "Wig Off",

                        "Valentine Short Animation",
                        "Ato Bibinii and BARBER TOSKI",
                        "Ato Bibinii - ONE CORNER",
                        "Ato Bibinii and cockatoo",

                        "Joeboy - Lonely ",
                        "Reekado Banks - Happy yourself",
                        "Young Jonn, Tiwa Savage - Let Them Know ft. Joeboy",
                        "Akwaboah - One Day",
                        "Joeboy - Focus ",
                        "Joeboy - Call",
                    ];

                    const works = document.querySelector(".works");

                    const viz_array = Object.keys(shorts);
                    const new_array = [];

                    const toggleDarkMode = () => {
                        const toggle_mode = document.querySelector(".toggle_mode");
                        var toggle = true;
                        var key = "toggle_key";
                        toggle = !toggle;
                        const local_toggle = localStorage.getItem(key);

                        toggle_mode.onclick = () => {
                            if ((toggle = !toggle)) {
                                localStorage.setItem(key, toggle);
                                document.body.classList.add("darkmode");
                            } else {
                                localStorage.setItem(key, toggle);
                                document.body.classList.remove("darkmode");
                            }
                        };

                        if (local_toggle === "true") {
                            document.body.classList.add("darkmode");
                        }

                        if (local_toggle === "false") {
                            document.body.classList.remove("darkmode");
                        }
                    };
                    toggleDarkMode();

                    //Vizualizer animations appending into DOM

                    viz_array.sort((a, b) => {
                        const figA = a.replace("viz_", "");
                        const figB = b.replace("viz_", "");

                        const numA = Number(figA);
                        const numB = Number(figB);

                        return numA - numB;
                    });



                    for (let i = 0; i < viz_array.length; i += 2) {

                        const obj = {
                            key_one: {
                                image_link: viz_array[i],
                                url: vizUrl_array[i],
                                anim_title: anim_titles[i],
                            },
                            key_two: {
                                image_link: viz_array[i + 1],
                                url: vizUrl_array[i + 1],
                                anim_title: anim_titles[i + 1],
                            },
                        };

                        new_array.push(obj);
                    }


                    new_array.forEach((items) => {
                        const vizualizer_container = document.createElement("div");
                        vizualizer_container.classList = "vizualizer_container";

                        const id_1 = items.key_one.image_link.replace("viz_", "");
                        const id_2 = items.key_two.image_link.replace("viz_", "");


                        if (mobile_screen.matches) {
                            const template = `
                            <div class="vizualizer">
                
                                <div class="viz_img_container" >
                                
                                <img class='viz_image' src="${shorts[items.key_one.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_1}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 <div class="glow"  data-scroll data-scroll-speed="0">
                                    <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                
                             </div>
                
                             <div class="vizualizer">
                
                                <div class="viz_img_container"
                                 data-scroll 
                                 data-scroll-speed="0">
                
                                <img class='viz_image' src="${shorts[items.key_two.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_2}  
                                 data-scroll 
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat  
                                 data-url=${items.key_two.url}
                                 data-title="${items.key_two.anim_title}"/>
                
                                 <div class="glow"
                                 data-scroll  
                                 data-scroll-speed="0"
                                 >
                                 <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                             </div>
                        `;

                            vizualizer_container.innerHTML = template;
                            works.appendChild(vizualizer_container);
                        } else {
                            const template = `
                            <div class="vizualizer">
                
                                <div class="viz_img_container" >
                                
                                <img class='viz_image' src="${shorts[items.key_one.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_1}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 <div class="glow"  data-scroll data-scroll-speed="0">
                                    <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                
                             </div>
                
                             <div class="vizualizer">
                
                                <div class="viz_img_container"
                                 data-scroll 
                                 data-scroll-speed="0.15">
                
                                <img class='viz_image' src="${shorts[items.key_two.image_link]
                                }" alt="poka studios vizualizers"
                                 data-id=${id_2}  
                                 data-scroll 
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat  
                                 data-url=${items.key_two.url}
                                 data-title="${items.key_two.anim_title}"/>
                
                                 <div class="glow"
                                 data-scroll  
                                 data-scroll-speed="0"
                                 >
                                 <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                             </div>
                        `;

                            vizualizer_container.innerHTML = template;
                            works.appendChild(vizualizer_container);
                        }


                    });

                    //Click to play video function begins here...

                    const iframe_container = document.querySelector(".iframe_container");
                    const iframe = document.querySelector("iframe");

                    document.addEventListener("click", (e) => {
                        const target = e.target.closest(".glow");

                        if (target) {
                            const viz = target.previousElementSibling;
                            const data_url = viz.getAttribute("data-url");
                            const iframe_url =
                                "https://www.youtube.com/embed/a&amp;controls=1";

                            iframe_container.style.cssText = `
                                display:block;
                            `;

                            var new_url = iframe_url.replace(/d(.*?)&/, `${data_url}`);
                            iframe.src = new_url;

                        }
                    });

                    const close = document.querySelector(".close");

                    close.onclick = () => {
                        iframe_container.style.display = "none";
                        iframe.src = "about:blank";
                        iframe.contentWindow.postMessage(
                            '{"event":"command","func":"stopVideo","args":""}',
                            "*"
                        );


                        history.go(-1);
                    };

                    setTimeout(() => {
                        const glow = document.querySelectorAll(".glow");
                        const viz_image = document.querySelector(".viz_image");
                        const imageHeight = viz_image.getBoundingClientRect();


                        glow.forEach((items, index) => {
                            // items.style.height = imageHeight.height + "px";
                        });

                        //Card 3d hover effect

                        let cardSize;
                        glow.forEach((items) => {
                            const rotateToMouse = (e) => {
                                const mouseX = e.clientX;
                                const mouseY = e.clientY;
                                const leftX = mouseX - cardSize.x;
                                const topY = mouseY - cardSize.y;

                                const center = {
                                    x: leftX - cardSize.width / 2,
                                    y: topY - cardSize.height / 2,
                                };

                                const distance = Math.sqrt(center.x ** 0.5 + center.y ** 2);

                                // allVizImages.forEach(cards=>{

                                //     cards.style.transform = `
                                //     rotate3d(
                                //     ${center.y / 100},
                                //     ${center.x / 100},
                                //     0,
                                //     ${Math.log(distance) * 2}deg
                                //     )
                                //      `

                                // })

                                items.style.backgroundImage = `
                                radial-gradient(
                                  circle at
                                  ${center.x * 2 + cardSize.width / 2}px
                                  ${center.y * 2 + cardSize.height / 2}px,
                                  #ffffff55,
                                  #0000000f
                                )
                              `;

                                items.style.cursor = "pointer";
                            };

                            items.addEventListener("mouseenter", () => {
                                cardSize = items.getBoundingClientRect();
                                document.addEventListener("mousemove", rotateToMouse);
                            });

                            items.addEventListener("mouseleave", () => {
                                document.removeEventListener("mousemove", rotateToMouse);
                                items.style.transform = "";
                                items.style.backgroundImage = "";
                            });
                        });

                        //Set intitial animation values with gsap.set



                        const allVizImages = document.querySelectorAll(".viz_image");

                        allVizImages.forEach((items) => {
                            const viz_id = items.getAttribute("data-id");
                            if (mobile_screen.matches) {
                                if (viz_id > 1) {
                                    const card_parent = items.parentElement.parentElement;
                                    gsap.set(card_parent, {
                                        //test value
                                        opacity: 1,
                                        scale: 0.85,
                                        y: 0,
                                    });
                                }
                            } else {
                                if (viz_id > 1) {
                                    const card_parent = items.parentElement.parentElement;
                                    gsap.set(card_parent, {
                                        //test value
                                        opacity: 1,
                                        scale: 0.85,
                                        y: 150,
                                    });
                                }
                            }
                        });

                        //Scroll trigger functions begin here...
                        setTimeout(() => {
                            window.addEventListener("works_trigger", (e) => {
                                const target = e.detail;
                                const in_view = target.target;
                                const card_parent = in_view.parentElement.parentElement;

                                // anim_title_h3.textContent = target_title
                                // current_img.setAttribute('src', target_img)
                                // anim_title.style.display = 'block'

                                gsap.to(card_parent, {
                                    duration: 1.5,
                                    opacity: 1,
                                    scale: 1,
                                    y: 0,
                                    ease: "power4.out",
                                });
                            });
                        }, 500)


                        // Function to create an IntersectionObserver

                        if (mobile_screen.matches) {
                            const options = {
                                root: null, // Use the viewport as the container
                                rootMargin: "-50% 0% -50% 0%", // No margin around the root
                                threshold: 0, // Trigger when 10% of the element is visible
                            };

                            const createObserver = () => {
                                const mobile_anim_title_h3 = document.querySelector(".mobile_anim_title h3");
                                const current_image = document.querySelector(".current_image img");

                                const observer = new IntersectionObserver((entries, observer) => {
                                    entries.forEach((entry) => {
                                        if (entry.isIntersecting) {

                                            const target_title = entry.target.getAttribute("data-title");
                                            const target_img = entry.target.getAttribute("src");
                                            const play_button = entry.target.nextElementSibling.querySelector('img')

                                            console.log(play_button)

                                            // anim_title_h3.textContent = target_title;
                                            mobile_anim_title_h3.textContent = target_title;
                                            current_image.src = target_img

                                            var inview_target = entry.target.parentElement.parentElement
                                            console.log(inview_target)

                                            gsap.to(inview_target, {
                                                duration: 1,
                                                opacity: 1,
                                                scale: 1,
                                                y: 0,
                                                ease: "power4.out",
                                            });

                                            play_button.style.opacity = 1
                                            // anim_title.style.display = "block";

                                            // Perform actions when the element is in view
                                            // entry.target.classList.add('in-view'); // Example: add a class

                                            // Optionally, unobserve the element if you only want to trigger once
                                            //observer.unobserve(entry.target);
                                        } else {
                                            const play_button = entry.target.nextElementSibling.querySelector('img')
                                            play_button.style.opacity = 0
                                            // Perform actions when the element is out of view
                                            // entry.target.classList.remove('in-view'); // Example: remove a class
                                        }
                                    });


                                }, options);

                                // Observe all elements with the class 'scroll-item'
                                allVizImages.forEach((items) => {
                                    observer.observe(items);
                                });
                            }

                            createObserver();
                        }

                        function createObserver() {
                            const options = {
                                root: null, // Use the viewport as the container
                                rootMargin: "0%", // No margin around the root
                                threshold: .1, // Trigger when 10% of the element is visible
                            };



                            const anim_title = document.querySelector(".anim_title");
                            const anim_title_h3 = document.querySelector(".anim_title h3");
                            const current_img = document.querySelector(".current_img");

                            const observer = new IntersectionObserver((entries, observer) => {
                                entries.forEach((entry) => {
                                    if (entry.isIntersecting) {

                                        const target_title =
                                            entry.target.getAttribute("data-title");
                                        const target_img = entry.target.getAttribute("src");

                                        anim_title_h3.textContent = target_title;
                                        current_img.setAttribute("src", target_img);
                                        anim_title.style.display = "block";



                                        // Perform actions when the element is in view
                                        // entry.target.classList.add('in-view'); // Example: add a class

                                        // Optionally, unobserve the element if you only want to trigger once
                                        //observer.unobserve(entry.target);
                                    } else {

                                        // Perform actions when the element is out of view
                                        // entry.target.classList.remove('in-view'); // Example: remove a class
                                    }
                                });


                            }, options);

                            // Observe all elements with the class 'scroll-item'
                            allVizImages.forEach((items) => {
                                observer.observe(items);
                            });
                        }
                        createObserver();






                        //Dom load setTimout Function ends here...
                    }, 500);

                    //Reesponive Height for mobile screens

                    setTimeout(() => {
                        const images = document.querySelectorAll('img')
                        var loadedCount = 0

                        images.forEach(image => {
                            if (image.complete) {
                                loadedCount++
                            } else {
                                image.addEventListener('load', () => {
                                    loadedCount++

                                    if (loadedCount === images.length) {
                                        // alert('they ones that didnt load have loaded')

                                        if (mobile_screen.matches) {

                                            //Gradient backgroudn height adaptation functions begins here...
                                            const gradient_background = document.querySelector(
                                                ".gradient_background"
                                            );
                                            const works_container = document.querySelector(".works_container");

                                            const works_height = works_container.getBoundingClientRect().height;
                                            gradient_background.style.height = works_height + "px";

                                            const layout = document.querySelector('.layout')
                                            layout.style.height = works_height + 100 + 'px'
                                            console.log(layout)

                                            works_container.style.height = works_height + 100 + 'px'


                                        }
                                    }
                                })
                            }

                            if (loadedCount === images.length) {

                                if (mobile_screen.matches) {

                                    //Gradient backgroudn height adaptation functions begins here...
                                    const gradient_background = document.querySelector(
                                        ".gradient_background"
                                    );
                                    const works_container = document.querySelector(".works_container");

                                    const works_height = works_container.getBoundingClientRect().height;
                                    gradient_background.style.height = works_height + "px";

                                    const layout = document.querySelector('.layout')
                                    layout.style.height = works_height + 100 + 'px'
                                    console.log(layout)

                                    works_container.style.height = works_height + 100 + 'px'

                                    gsap.to(layout, {
                                        display: 'block',
                                        opacity: 1
                                    })
                                }

                            }
                        })

                    }, 100)

                    if (mobile_screen.matches) {

                        const layout = document.querySelector('.layout')

                        gsap.to(layout, {
                            display: 'block',
                            opacity: 1
                        })
                    }

                    //Gradient backgroudn height adaptation functions begins here...
                    const gradient_background = document.querySelector(
                        ".gradient_background"
                    );
                    const works_container = document.querySelector(".works_container");

                    const works_height = works_container.getBoundingClientRect().height;
                    gradient_background.style.height = works_height + "px";

                   

                    window.addEventListener("contactTrigger", () => {
                        const challenge_img = document.querySelector(".challenge_img");
                        const challenge_content =
                            document.querySelector("#challenge_content");
                        const contact_img = document.querySelector(".contact_img");
                        const contact_content = document.querySelector("#contact_content");
                        const footer_arr = [
                            challenge_img,
                            challenge_content,
                            contact_img,
                            contact_content,
                        ];

                        gsap.to(footer_arr, {
                            alpha: 1,
                            stagger: 0.2,
                            duration: 1.5,
                            ease: "power4.out",
                        });
                    });

                    // const iframe = document.querySelector('iframe')
                    var string_one = iframe.getAttribute("src");
                    const text = "d/bobby&amp;";
                    var new_url = string_one.replace(/d(.*?)&/, `${text}`);


                    locomotiveScroll.start();

                    //End of MainFunction//
                }

                try {
                    MainFunction();
                } catch (error) { }
            },
        },
        {
            namespace: "gallery",
            afterEnter() {
                if (mobile_screen.matches) {
                    setTimeout(() => {
                        initScroll();
                        let scrollX = 0
                        let scrollY = 0

                        barba.hooks.leave(() => {
                            scrollX = barba.history.current.scroll.x;
                            scrollY = barba.history.current.scroll.y;
                        });

                        // then later in the code...
                        window.scrollTo(scrollX, scrollY);



                    }, 500);
                } else {
                    setTimeout(() => {
                        initScroll();
                        scroll.scrollTo(0, { duration: 3, easeing: [0.25, 0.0, 0.35, 1.0] });
                        // resolve();
                    }, 500); // Adjust delay as needed
                }

                //Responsive Navbar
                const nav = document.querySelector("nav");
                const menu_container = document.querySelector(".menu_container");
                let lastScrollPosition = 0;

                var cursorBool = false;

                menu_container.addEventListener("mouseenter", () => {
                    cursorBool = true;
                });

                nav.addEventListener("mouseleave", () => {
                    cursorBool = false;
                });

                menu_container.addEventListener("touchmove", () => {
                    cursorBool = true;
                });

                // nav.addEventListener("touchend", () => {
                //     cursorBool = false;
                // });

                
                    if (mobile_screen.matches) {
                        window.addEventListener("scroll", () => {
                            if (cursorBool == true) {
                                return;
    
                            }
    
                            nav.classList.remove("add_nav");
                        })
                       

                    } else {
                        window.addEventListener("scroll", () => {
                            if (cursorBool == true) {
                                return;
    
                            }
    
                            const currentScrollPosition = window.scrollY;
    
                            if (currentScrollPosition > lastScrollPosition) {
                                // Scrolling down
    
                                nav.classList.add("add_nav");
                            } else if (currentScrollPosition < lastScrollPosition) {
                                // Scrolling up
    
                                nav.classList.remove("add_nav");
                            }
    
                            // Update the last scroll position
                            lastScrollPosition = currentScrollPosition;
                        })
                     
                    }

               


                //Menu pane codes here...
                const menu = document.querySelector(".menu_trigger");

                const menu_items = document.querySelector(".menu_items");
                const menu_items_array = [...menu_items.children];
                const menu_buttons = menu_items.querySelectorAll(".main_menu");
                const works_menu = menu_items.querySelectorAll(".works_menu");
                const menu_arrow = document.querySelector(".menu_arrow");
                const arrow_menu_array = document.querySelectorAll('.menu_arrow, .arrow_menu')
                const timeout = 500;
                var toggle_bool = true;
                const timeline = gsap.timeline();

                toggle_bool = !toggle_bool;

                menu.onclick = () => {
                    //nav.classList.toggle('toggle_nav')

                    if ((toggle_bool = !toggle_bool)) {
                        gsap.fromTo(
                            nav,
                            {
                                height: "auto",
                            },
                            {
                                height: "100dvh",
                                duration: 1,
                                ease: "power4.out",
                                //  onComplete:()=>{
                                //     menu_container.classList.add('active')
                                //  }
                            }
                        );

                        menu_container.classList.add("active");
                        gsap.to(menu_container, {
                            opacity: 1,
                        });

                        setTimeout(() => {
                            timeline.to(menu_items_array, {
                                width: "100%",
                                duration: .8,
                                stagger: 0.2,
                                ease: "power4.out",
                            });
                            timeline.to(menu_buttons, {
                                top: "0",
                                duration: .8,
                                ease: "power4.out",
                                delay: -1,
                            });
                        }, timeout);

                        gsap.to(nav_footer, {
                            opacity: 1,
                            delay: 1.3
                        })

                        // nav.style.backgroundColor = '#141321e3'
                    } else {
                        menu_container.classList.remove("active");
                        gsap.to(menu_container, {
                            opacity: 0,
                        });

                        menu_items_array.forEach((items) => {
                            items.style.width = "0";
                        });

                        menu_buttons.forEach((items) => {
                            items.style.cssText = `
                top:200px
            `;
                        });

                        gsap.fromTo(
                            nav,
                            {
                                height: "100svh",
                            },
                            {
                                height: "83px",
                                duration: 1,
                                ease: "power4.out",
                            }
                        );

                        if (mobile_screen.matches) {
                            gsap.fromTo(
                                nav,
                                {
                                    height: "100dvh",
                                },
                                {
                                    height: "72px",
                                    duration: 1,
                                    ease: "power4.out",
                                    onComplete: () => {
                                        if (mobile_screen.matches) {
                                            gsap.to(nav_footer, {
                                                opacity: 0
                                            })
                                        }
                                    }
                                }
                            );
                        }

                        // nav.style.backgroundColor = '#14132185'
                    }
                };

                var works_bool = true;
                const menu_tl = gsap.timeline();
                works_bool = !works_bool;

                arrow_menu_array.forEach(items => {
                    items.onclick = () => {
                        menu_arrow.classList.toggle('toggle_arrow')


                        if (works_bool = !works_bool) {

                            menu_tl.to(menu_buttons, {
                                top: '200px',
                                duration: .8,
                                ease: 'power4.in',
                            })

                            menu_tl.to(works_menu, {
                                opacity: 1,
                                top: '0',
                                duration: .8,
                                ease: 'power4.out',
                            })

                        } else {
                            menu_tl.to(works_menu, {
                                opacity: 0,
                                top: '200px',
                                duration: .8,
                                ease: 'power4.in',
                            })

                            menu_tl.to(menu_buttons, {
                                top: '0',
                                duration: .8,
                                ease: 'power4.out',
                            })
                        }
                    }
                })

                //End of responsive nav codes...

                function MainFunction() {
                    const vizUrl_array = [
                        "d/2H5MPH90PtI?si=pmesczYlqup62FJx&amp;",
                        "d/CxRdXDG9nxU?si=iUGc4olO30U5BlBm&amp;",
                        "d/sIV2qgBfXwg?si=T1WP7Y6Rje86EGoP&amp;",
                        "d/FJreIHeUvVs?si=gy-GOhT9BWjSF4Ej&amp;",

                        "d/YDSbFczeYaM?si=qqA_y3Jf1dP22fSP&amp;",
                        "d/7I2KgPYEyOQ?si=xLPux75vIIXRlpB9&amp;",
                        "d/0Ii1iof8K4Y?si=dIscryXyEB-a7z7V&amp;",
                        "d/29IOZPnyKcs?si=u85Q6ElDMSssaoTC&amp;",
                        "d/iiooBnAgXOU?si=uPq3wYigHUQSKMhf&amp;",
                        "d/7zq1xQ1vKVw?si=3FDFUigGLp4syVtc&amp;",

                        "d/CDWVeVZa-DA?si=C7Spa6k7F34PQDi9&amp;",
                        "d/CDlowo-jes0?si=yEixi-7nWCZLqO2m&amp;",
                        "d/1j8WzAFcsd0?si=bYxJiTHJSrGI0inb&amp;",
                        "d/jm-7qVrU6Po?si=swM3cbAvUqgI2DSO&amp;",
                    ];

                    const anim_titles = [
                        "Together, Forever ❤️",
                        "Angel NobodyKnows. Y3 FAST!",
                        "The Redeemer",
                        "Wig Off",

                        "Valentine Short Animation",
                        "Ato Bibinii and BARBER TOSKI",
                        "Ato Bibinii - ONE CORNER",
                        "Ato Bibinii and cockatoo",

                        "Joeboy - Lonely ",
                        "Reekado Banks - Happy yourself",
                        "Young Jonn, Tiwa Savage - Let Them Know ft. Joeboy",
                        "Akwaboah - One Day",
                        "Joeboy - Focus ",
                        "Joeboy - Call",
                    ];

                    const works = document.querySelector(".works");
                    const arrow_left = document.querySelector("#arrow_left");
                    const arrow_right = document.querySelector("#arrow_right");

                    const viz_array = Object.keys(gallerys);
                    const gallery_array = Object.keys(gallery_images);
                    const new_array = [];
                    const new_gallery_array = [];

                    gallery_array.sort((a, b) => {
                        const fig1 = Number(a.replace("art_", ""));
                        const fig2 = Number(b.replace("art_", ""));

                        return fig1 - fig2;
                    });

                    const toggleDarkMode = () => {
                        const toggle_mode = document.querySelector(".toggle_mode");
                        var toggle = true;
                        var key = "toggle_key";
                        toggle = !toggle;
                        const local_toggle = localStorage.getItem(key);

                        toggle_mode.onclick = () => {
                            if ((toggle = !toggle)) {
                                localStorage.setItem(key, toggle);
                                document.body.classList.add("darkmode");
                            } else {
                                localStorage.setItem(key, toggle);
                                document.body.classList.remove("darkmode");
                            }
                        };

                        if (local_toggle === "true") {
                            document.body.classList.add("darkmode");
                        }

                        if (local_toggle === "false") {
                            document.body.classList.remove("darkmode");
                        }
                    };
                    toggleDarkMode();

                    //Vizualizer animations appending into DOM

                    viz_array.sort((a, b) => {
                        const figA = a.replace("viz_", "");
                        const figB = b.replace("viz_", "");

                        const numA = Number(figA);
                        const numB = Number(figB);

                        return numA - numB;
                    });



                    for (let i = 0; i < viz_array.length; i++) {

                        const obj = {
                            key_one: {
                                image_link: viz_array[i],
                                url: vizUrl_array[i],
                                anim_title: anim_titles[i],
                            },
                        };

                        new_array.push(obj);
                    }


                    new_array.forEach((items, index) => {
                        const vizualizer_container = document.createElement("div");
                        vizualizer_container.classList = "vizualizer_container";

                        const id_1 = items.key_one.image_link.replace("viz_", "");



                        const template = `
                            <div class="vizualizer ">
                
                                <div class="gallery_img_container" >
                                
                                <img class='viz_image gallery_picture' src="${gallerys[items.key_one.image_link]
                            }" alt="poka studios vizualizers"
                                 data-id=${index}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start"
                                 data-scroll-offset="0"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 
                                </div>
                
                                 
                
                             </div>
                
                            
                        `;

                        vizualizer_container.innerHTML = template;
                        works.appendChild(vizualizer_container);
                    });

                    //Click to play video function begins here...

                    const gallery_container = document.querySelector(".gallery_container");
                    const gallery_container_img = document.querySelector(".picture_holder img");
                    // const iframe = document.querySelector("iframe");

                    document.addEventListener("click", (e) => {
                        const target = e.target.closest(".gallery_picture");

                        if (target) {
                            gallery_container.style.cssText = `
                                display:grid;
                            `;

                          
                            const thumbnail_id = target.getAttribute("data-id");



                            gallery_array.forEach((items, index) => {

                                if (thumbnail_id == index) {
                                    gallery_container_img.src =
                                        gallery_images[gallery_array[index]];

                                    if (index < gallery_array.length - 1) {
                                        arrow_right.onclick = () => {
                                            index++;
                                            gallery_container_img.src =
                                                gallery_images[gallery_array[index]];
                                        };
                                        
                                    }

                                    arrow_left.onclick = () => {
                                        if (index > 0) {
                                            index--;
                                            gallery_container_img.src =
                                                gallery_images[gallery_array[index]];
                                        }
                                       
                                    };

                                   
                                }
                            });

                            // const data_url = viz.getAttribute('data-url')
                            // const iframe_url = "https://www.youtube.com/embed/a&amp;controls=1"

                            // var new_url = iframe_url.replace(/d(.*?)&/, `${data_url}`)
                            // iframe.src = new_url

                        }
                    });

                    const close = document.querySelector(".close_gallery");

                    close.onclick = () => {
                        gallery_container.style.display = "none";
                        // iframe.src = 'about:blank'
                        // iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
                    };

                    setTimeout(() => {
                        // const glow = document.querySelectorAll(".glow");
                        // const viz_image = document.querySelector(".viz_image");
                        // const imageHeight = viz_image.getBoundingClientRect();


                        // glow.forEach((items, index) => {
                        //     items.style.height = imageHeight.height + "px";
                        // });

                        //Card 3d hover effect

                        let cardSize;
                        glow.forEach((items) => {
                            const rotateToMouse = (e) => {
                                const mouseX = e.clientX;
                                const mouseY = e.clientY;
                                const leftX = mouseX - cardSize.x;
                                const topY = mouseY - cardSize.y;

                                const center = {
                                    x: leftX - cardSize.width / 2,
                                    y: topY - cardSize.height / 2,
                                };

                                const distance = Math.sqrt(center.x ** 0.5 + center.y ** 2);

                                // allVizImages.forEach(cards=>{

                                //     cards.style.transform = `
                                //     rotate3d(
                                //     ${center.y / 100},
                                //     ${center.x / 100},
                                //     0,
                                //     ${Math.log(distance) * 2}deg
                                //     )
                                //      `

                                // })

                                items.style.backgroundImage = `
                                radial-gradient(
                                  circle at
                                  ${center.x * 2 + cardSize.width / 2}px
                                  ${center.y * 2 + cardSize.height / 2}px,
                                  #ffffff55,
                                  #0000000f
                                )
                              `;

                                items.style.cursor = "pointer";
                            };

                            items.addEventListener("mouseenter", () => {
                                cardSize = items.getBoundingClientRect();
                                document.addEventListener("mousemove", rotateToMouse);
                            });

                            items.addEventListener("mouseleave", () => {
                                document.removeEventListener("mousemove", rotateToMouse);
                                items.style.transform = "";
                                items.style.backgroundImage = "";
                            });
                        });

                        const allVizImages = document.querySelectorAll(".viz_image");

                        //Scroll trigger functions begin here...
                        window.addEventListener("works_trigger", (e) => {
                            const anim_title = document.querySelector(".anim_title");
                            const anim_title_h3 = document.querySelector(".anim_title h3");
                            const current_img = document.querySelector(".current_img");
                            const target = e.detail;
                            const way = e.detail.way;
                            const in_view = target.target;
                            const target_title = in_view.getAttribute("data-title");
                            const target_img = in_view.getAttribute("src");
                            const card_parent = in_view.parentElement.parentElement;

                            // anim_title_h3.textContent = target_title
                            // current_img.setAttribute('src', target_img)
                            // anim_title.style.display = 'block'

                            gsap.to(card_parent, {
                                duration: 1.5,
                                opacity: 1,
                                scale: 1,
                                y: 0,
                                ease: "power4.out",
                            });
                        });

                        // Function to create an IntersectionObserver
                        function createObserver() {
                            const options = {
                                root: null, // Use the viewport as the container
                                rootMargin: "0px", // No margin around the root
                                threshold: 0.1, // Trigger when 10% of the element is visible
                            };

                            const anim_title = document.querySelector(".anim_title");
                            const anim_title_h3 = document.querySelector(".anim_title h3");
                            const current_img = document.querySelector(".current_img");

                            const observer = new IntersectionObserver((entries, observer) => {
                                entries.forEach((entry) => {
                                    if (entry.isIntersecting) {


                                        const target_title =
                                            entry.target.getAttribute("data-title");
                                        const target_img = entry.target.getAttribute("src");

                                        anim_title_h3.textContent = target_title;
                                        current_img.setAttribute("src", target_img);
                                        anim_title.style.display = "block";

                                        // Perform actions when the element is in view
                                        // entry.target.classList.add('in-view'); // Example: add a class

                                        // Optionally, unobserve the element if you only want to trigger once
                                        //observer.unobserve(entry.target);
                                    } else {


                                        // Perform actions when the element is out of view
                                        // entry.target.classList.remove('in-view'); // Example: remove a class
                                    }
                                });
                            }, options);

                            // Observe all elements with the class 'scroll-item'
                            allVizImages.forEach((items) => {
                                observer.observe(items);
                            });
                        }
                        createObserver();

                        //Set intitial animation values with gsap.set
                        allVizImages.forEach((items) => {
                            const viz_id = items.getAttribute("data-id");

                            if (viz_id > 1) {
                                const card_parent = items.parentElement.parentElement;

                                gsap.set(card_parent, {
                                    //test value
                                    opacity: 0,
                                    scale: 0.85,
                                    y: 150,
                                });
                            }
                        });

                        //Dom load setTimout Function ends here...
                    }, 100);

                    //Gradient backgroudn height adaptation functions begins here...
                    setTimeout(() => {
                        const gradient_background = document.querySelector(
                            ".gradient_background"
                        );
                        const works_container = document.querySelector(".works_container");

                        const works_height = works_container.getBoundingClientRect().height;
                        gradient_background.style.height = works_height + "px";
                    }, 500)


                 

                    window.addEventListener("contactTrigger", () => {
                        const challenge_img = document.querySelector(".challenge_img");
                        const challenge_content =
                            document.querySelector("#challenge_content");
                        const contact_img = document.querySelector(".contact_img");
                        const contact_content = document.querySelector("#contact_content");
                        const footer_arr = [
                            challenge_img,
                            challenge_content,
                            contact_img,
                            contact_content,
                        ];

                        gsap.to(footer_arr, {
                            alpha: 1,
                            stagger: 0.2,
                            duration: 1.5,
                            ease: "power4.out",
                        });
                    });

                    // const iframe = document.querySelector('iframe')
                    // var string_one = iframe.getAttribute("src");
                    // const text = "d/bobby&amp;";
                    // var new_url = string_one.replace(/d(.*?)&/, `${text}`);

                    locomotiveScroll.start();

                    //End of MainFunction//
                }

                try {
                    MainFunction();
                } catch (error) { }
            },
        },
    ],
});

